import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import './App.css'
import {Form, FormCell} from 'react-weui';
import {Input, Label, Button, ButtonArea, Radio, Checkbox, TextArea} from 'react-weui';
import {CellsTitle, CellBody, CellFooter} from 'react-weui';
import qs from 'qs';

import 'weui';
import 'react-weui/build/packages/react-weui.css';

@inject('routingStore', 'sessionStore')
@observer
export default class Registration extends Component {

  @observable displayAlert = false;
  terms = "DATE OF LAST MODIFICATION: Oct. 9, 2018\n\n\
Terms of service\n\n\
\
1. Intro\n\
Welcome to UPRO!\n\n\
This page explains our terms of use about which it governs your use of our App/website or services.\
\n\
By using this App/website (the “Site”) and services (together with the Site, the “Services”) offered by Upro Network Technology Limited (together with its parents, subsidiaries, affiliates, agents, consultants, representatives, employees, officers, and directors — collectively, “Upro,” “we,” or “us”), you’re agreeing to these legally binding rules (the “Terms”). You’re also agreeing to our Privacy Policy and agreeing to follow any other rules on the Site.\
\n\n\
\
2. Definition\n\
Poster: A registered user of Upro who post job(s).\
\n\
Referrer: A registered user of Upro who refers candidate(s).\
\n\
Registered user: a user who registered an account with Upro.\
\n\n\
3. Eligibility\n\
You may only use the Services if you are over 18 years old.\n\n\
4. Content\n\
4.1. Account creation\n\
Without account registration, you can grant a limited browsing access to Upro. To fully access and utilize the function of Upro, you will need to register and set up an account with a password. Please ensure all the information given to us is true and accurate. \n\n\
You’re responsible for all the activity on your account, and for keeping your password confidential. If you become aware of any unauthorized use of your account by someone, please report to support@Upro.link immediately. \n\n\
You may not (a) Share your account information (except with an authorized account administrator) or (b) use another person’s account. Your account administrator may use your account information to manage your use and access to the Services.\n\n\
To prevent fraud and abuse, users are limited to one active account. Any additional account determined to be created to circumvent guidelines, promote competitive advantages, or mislead users will be disabled. Mass account creation may result in termination of all related accounts. \
\n\n\
4.2 How it works\n\
Upro provides a platform on which every registered user can post a job, refer candidate(s) online. Users can save posts, referrals in their profile.\n\n\
4.3 Registered Users\n\
All posts summited to us will subject to approval before publishing to the public. When a user summits a post onto the platform for free, other users can give feedback and make reviews. When a user summits a candidate CV and charges for a price, part of the information of the candidate CV will be hidden.  Only users who accept the terms of referral can make a review on that particular CV.\n\n\
Users who refer candidate CV onto the platform have no obligation or responsibility to ensure the success of the referral.  However, users should work in good faith and show high standard of passion and effort in communication when posting job or referring CV without misleading or misrepresenting facts to other users intentionally. Also, all users should not replicate or repost contents from others. Please refer to the rules and prohibitions of referring a candidate onto Upro.\
\n\n\
4.5 Code of conduct and prohibitions\n\
We value our users’ experience. We expect all our users to behave responsibly and maintain a certain degree of courtesy and professionalism. All users are prohibited to do the following things:\n\n\
4.5.1.	violate applicable law or take any action that infringes or violates other people’s rights, violates the law, or breaches any contract or legal duty you have towards anyone.\n\n\
4.5.2.	disrupt, interfere with, or inhibit any other user, including but not limited to any activities that are intimidating, harassing, threatening, abusive, defamatory, libellous, tortious, obscene, profane, invasive of another person’s privacy or inciting others to commit violence\n\n\
4.5.3.	Intentionally post any information that is false, misleading, mispresent facts or inaccurate. Commit any deceptive or fraudulent activities.\n\n\
4.5.4.	share content or engage in behaviour that violates anyone’s Intellectual Property Right (“Intellectual Property Rights” means copyright, moral rights, trademark, trade dress, patent, trade secret, unfair competition, right of privacy, right of publicity, and any other proprietary rights.);\n\n\
4.5.5.	Access or attempt to access our Sites by any means other than the interface we authorized\n\n\
4.5.6.	Circumvent any access or use restrictions put into place to prevent certain uses of the services\n\n\
4.5.7.	Use the content to construct any kind of database, or use any data mining or similar data gathering and extraction methods in connection with the Services; or\n\n\
4.5.8.	distribute unsolicited or unauthorized advertising or promotional material, or any junk mail, spam, chain letters or pyramid schemes. Don’t run mail lists, listservs, or any kind of auto-responder or spam on or through the Site.\n\n\
4.5.9.	attempt to disable, impair, destroy, or distribute software viruses, or anything else (code, films, programs) designed to interfere with the proper function of any software, hardware, or equipment on the Site (whether it belongs to Upro or another party).\n\n\
4.5.10.	impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;\n\n\
4.5.11.	abuse other users’ personal information.\n\n\
4.5.12.	damage or get unauthorized access to any system, data, password, or other information, whether it belongs to us or another party.\n\n\
4.5.13.	take any action that imposes an unreasonable load on our infrastructure, or on our third-party providers. (We reserve the right to determine what’s reasonable.) \n\n\
4.5.14.	take apart or reverse engineer any aspect of Upro in an effort to access things like source code, underlying ideas, or algorithms.\
\n\n\
The content posted should not consist of any of the following item:\n\
-	Illegal, heavily regulated, or potentially dangerous to the society.\n\
-	Misleading, misrepresent or sharing inaccurate facts or information\n\
-	Copy, repackage or resell a previously-created post, without adding anything new or aiming to iterate on the idea in any way.\n\
-	place advertisement of any products or services in the Services except with our prior written approval;\n\
-	Offer any kinds of rewards or by any means deliver any goods to other users.\n\
-	Promotion of discrimination, bigotry, or intolerance towards marginalized groups\n\
-	For the aim of any kind of political activities or fundraising.\n\
-	Offensive material (e.g., hate speech, encouraging violence against others, etc).\n\
-	Pornographic material.\n\
-	Weapons, replicas of weapons, and weapon accessories.\n\n\
All posts summited to us will subject to approval before publishing to the public. We reserve the rights to approve, ban or remove any post.\
\n\n\
4.6 User generated content, Ownership and IP\n\
4.6.1.	The content on Urpo is protected in various ways. We grant you a license to reproduce content from the Services for personal use only. This license covers both Upro’s own protected content and user-generated content on the Site. (This license is worldwide, non-exclusive, non-sublicensable, and non-transferable.) If you want to use, reproduce, modify, distribute, or store any of this content for a commercial purpose, you need prior written permission from us or the relevant copyright holder. A “commercial purpose” means you intend to use, sell, license, rent, or otherwise exploit content for commercial use, in any way.\n\n\
4.6.2.	Our Services are protected in various ways, including copyrights, trademarks, service marks, patents, trade secrets, and other associated intellectual property rights and laws. You agree to respect all copyright and other legal notices, information, and restrictions contained in any content accessed through the Site. You also agree not to change, translate, or otherwise create derivative works of the Service.\n\n\
5. Other websites\n\
Our Services may contain links to other websites. When you access third-party websites, you do so at your own risk. We don’t control or endorse those sites.\n\n\
6. Our rights\n\
6.1.	To operate, we need to be able to maintain control over what happens on our website. We reserve the right to make decisions to protect the health and integrity of our system. We don’t take these powers lightly, and we only use them when we absolutely have to.\n\n\
6.2.	Upro reserves these rights:\n\
-	We reserve the right to make changes to the Site and Services without notice or liability.\n\
-	We have the right to decide who’s eligible to use and access the Site and Services. We can terminate and cancel accounts or decline to offer our Services. We can change our eligibility criteria at any time. \n\
-	We have the right to delete any posts, articles or content, at any time and for any reason.\n\
-	We have the right to reject, cancel, interrupt, remove, or suspend any post at any time and for any reason.\n\
-	We are not liable for any damages as a result of any of these actions, and it is our policy not to comment on the reasons for any such action.\n\n\
\
7. Dispute resolution\n\
Disputes with users\n\n\
If you are in dispute with a user of our Services, you are encouraged to contact that user to resolve the situation amicably. If the dispute is notified to us, we will attempt in good faith to facilitate resolution of the dispute but is under no obligation to resolve the same. You release us from any claims, demands and damage arising out of your disputes with users of our Services.\
\n\n\
8. Warranty disclaimers\n\
Warranty. By uploading your content to the Services, you agree that you have: (a) all necessary licenses and permissions, to use and Share your content and (b) the rights necessary to grant the licenses in these terms.\n\n\
Your use our Site, Services and its contents is solely at your own risk. They are provided to you “as is” and “as available” and without warranty of any kind, express or implied. UPRO SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. We make no commitments about the content within the Services. We further disclaim any warranty that (a) the Services or Software will meet your requirements or will be constantly available, uninterrupted, timely, secure, or error-free; (b) the results that may be obtained from the use of the Services or Software will be effective, accurate, or reliable; (c) the quality of the Services or Software will meet your expectations; or that (d) any errors or defects in the Services or Software will be corrected.\n\n\
We specifically disclaim any liability for any actions resulting from your use of any Services or Software. You may use and access the Services or Software at your own discretion and risk, and you are solely responsible for any damage to your computer system or loss of data that results from the use and access of any Service or Software.\n\n\
No advice or information, whether oral or written, obtained by you from the Site or from the Services shall create any representation, warranty or guarantee. Furthermore, you acknowledge that Upro has no obligation to support or maintain the Services.\n\n\
Upro disclaims any and all liability or responsibility in relation to the content made available through the Services, including but not limited to the Content uploaded by users or the third-party content and services. Thinkclash is not responsible or liable in any manner for the third-party content and services associated with or utilized in connection with the Services, including the failure of such third-party content and services.\n\n\
9. Limitation of liability\n\
To the fullest extent permitted by law, in no event will Urpo, its directors, employees, partners, suppliers, or content providers be liable for any indirect, incidental, punitive, consequential, special, or exemplary damages of any kind, including but not limited to damages \n\n\
(i) resulting from your access to, use of, inability to access or use the Services; \n\
(ii) any technical, factual, textual or typographical inaccuracies, errors or omissions on or relating to our site (including using our applications or software) or any information on our site;\n\
(iii) any delay in providing, or failure to provide or make available, merchandise or services, or any negligent provision of merchandise or services;\n\
(iv) for any lost profits, business or goodwill, data loss, or cost of procurement or substitute goods or services; or \n\
(v) for any conduct of content of any third party on the Site. \n\n\
\
10. Indemnification\n\
You will indemnify us and our subsidiaries, affiliates, officers, agents, employees, partners, and licensors from any claim, demand, loss, or damages, including reasonable attorneys’ fees, arising out of or related to your content, your use of the Services or Software, or your violation of these terms.\n\n\
\
11. Remove content, Termination and deleting account\n\
Content removal\n\
You may revoke the license to your content and terminate our rights at any time by removing your content from the Service. However, some copies of your content may be retained as part of our routine backups.\n\n\
Termination of account\n\n\
Termination by You. You can delete your account at any time. Deleting your account won’t make content you’ve already posted go away, nor relieve you of any obligation to pay any outstanding fees. All provisions of this agreement survive termination of an account, including any perpetual licenses you have granted, your indemnification obligations, our warranty disclaimers or limitations of liabilities, dispute resolution provisions, and our rights regarding any content you’ve already submitted to the Site. Stated in these terms.\n\n\
Termination by Us.  Unless stated in Additional Terms, we may, at any time, terminate your right to use and access the Services or Software if:\n\n\
(a) you breach any provision of these terms (or act in a manner that clearly shows you do not intend to, or are unable to, comply with these terms);  \n\
(b) we are required to do so by law\n\
(c) we elect to discontinue the Services or Software, in whole or in part\n\n\
12. Screening and reporting\n\
Screening. We will to our greatest extent review all the contents uploaded to Upro, we may also use available technologies or processes to screen for certain types of illegal content or other abusive content or behaviour.\n\n\
Reporting Violations\n\
We try our best to screen off inappropriate contents in order to create the best user experience for you on Upro. If you come across any content that you find it may violate our Terms of Service, please do not hesitate and report to us immediately. All cases will be reviewed and investigated by our team. \n\n\
\
13. Disclosure\n\
Disclosure. We may access or disclose information about you, or your use of the Services, (a) when it is required by law (b) to respond to your requests for customer service support; or (c) when we, in our discretion, think it is necessary to protect the rights, property, or personal safety of us, our users, or the public.\n\n\
\
14. Modifications\n\
We may modify these terms or any additional terms that apply to a Service or Software to, for example, reflect changes to the law or changes to our Services or Software. You should look at the terms regularly. We will post notice of modifications to these terms on this page. We will post notice of modified additional terms in the applicable Service or Software. By continuing to use or access the Services or Software after the revisions come into effect, you agree to be bound by the revised terms.\n\n\
15. Governing law and jurisdiction\n\n\
This Agreement is governed by and shall be construed in accordance with the laws of Hong Kong.\n\n\
Both parties irrevocably agree that the courts of Hong Kong shall have the exclusive jurisdiction to determine any dispute arising out of or in connection with this Agreement.\n\n\
\
\n\
Privacy Policy\n\n\
\
Types of personal data collected by us may include:\n\n\
\
Personal information and contact information such as your name, gender, date of birth, email, telephone number, social media contact, country of residence, residential address, mailing address, billing address, personal interest, login, and password details. We will ask for this information if you register as a user of the Services, subscribe to our newsletters, upload or submit any material through Upro, or request any information;\n\n\
Your business information such as company name and business title;\n\n\
Details of any requests or transactions you make through the Services. All payment information you submitted is collected and used by our payment processors in accordance with their privacy policies. Upro doesn’t store your payment information apart from the last four digits of your account, expiration date, and country, which we require for tax, government regulatory, and security purposes;\n\n\
Information about your activity on and interaction with Upro including but not limited to your IP address, the type of device or browser you use, real-time geographic location data, browser settings, browsing records, other Internet log information of your computer or mobile device and your actions on the site\n\n\
Communications you send to us (for example, when you ask for support, send us questions or comments, or report a problem); and\n\n\
Information that you submit to Upro in the form of comments, contributions to discussions, or messages to other users.\
\n\n\
\
1. How to use\n\
We use the information collected for the following purposes:\
\n\
-	To keep your account secure;\n\
-	To enable us to provide you with our Services, and to improve, promote, and protect our Services;\n\
-	To set up and administer your account, contact you, and customize your experience on Upro (for example, to show you projects that we think may interest you based on your previous use); \n\
-	To track and Better understand how our Site are being used so we can improve them and engage and retain users\n\
-	To Diagnose problems in our site\n\
-	To send you information about us, new posts, special offers, and similar information\n\
-	Conducting market research about our customers, their interests, and the effectiveness of our marketing campaigns\n\
-	To reduce fraud and enhance software piracy\n\n\
\
2. CONFIDENTIALITY, DISCLOSURE AND SECURITY OF PERSONAL DATA\n\
Information that is shared publicly:\n\n\
-	Account details: account name, date of account registered\n\
-	Any information you add to your profile (profile photo, experience, bio, location, contacts and social media account)\n\
-	Any post that you have summited\n\
-	Any candidate(s) you have referred on Upro\n\n\
Information that isn’t shared publicly\n\
-	Password details\n\
-	IP address\n\
-	Phone number (unless you post it on your profile)\n\
-	Communication content you send to us (report, support request, comment to us, etc.)\n\n\
We will not post anything to your Facebook, Twitter, Instagram or any other third-party accounts without your permission. We do not and will not sell your data.\n\n\
Information that’s shared with trusted third-party services\
\n\
We may share your information with certain trusted third-party services to help us provide, improve, promote, or protect Upro’s Services (such as when we use services that help us manage our ads on other sites). \n\n\
When we share data with third-party services that support our delivery of the Upro Services, we require that they use your information only for the purposes we’ve authorized, and that they protect your personal information at least to the same standards we do. We may also share information that’s aggregated and anonymized in a way that it doesn’t directly identify you.\n\n\
Information that’s shared with Users\n\
When you agree terms of referring candidate CV, the Exporter will receive the following information:\n\
-	Your account name\n\
-	The amount you have paid for the post\n\
-	Your date of purchase\n\
The seller will never receive your credit card details or other payment information.\n\n\
\n\
Information that is shared to protect Upro and comply with the law\n\
We reserve the right to disclose personal information when we think by doing so is reasonably necessary to comply with the law or law enforcement, to prevent fraud or abuse, or to protect Upro’s legal rights.\
\n\n\
All reasonable efforts will be made to ensure that any personal data held by us is stored in a secure and safe place, and access to the data is restricted to authorised personnel only.\n\n\
All personal data which we collect will be kept confidential to the best of our ability. However, we will not guarantee the security of transmission. \
\n\n\
3. Cookies\n\
We use tracking technologies on our Services. These enable us to understand how you use our Services which, in turn, helps us to provide and improve our Services. However, the tracking technologies do not access, receive or collect information which can be used to identify you.\n\n\
What are cookies\
\n\
Cookies are small data files that are issued to your device when you visit a website and that store information about your use of a service. Cookies are used for a number of purposes, including to display the most appropriate content based on your interests and activity on our Services, estimate and report our Service's total audience size and traffic, and conduct research to improve our Services. \n\n\
Why we use them\n\
We use cookies and these other technologies to make your browsing and inspiring experience better, such as \n\n\
-	recognising you whenever you visit this website\n\
-	Keeping track of the information you have provided to us \n\
-	Remembering your preferences or where you left off in your use of our Site\n\
-	Measuring your use of and understand your likely interests on our site. so that we can improve them, tailor our websites to your likely interests, and conduct market research.\n\n\
The information we obtain from our use of cookies may include receiving information about your computer or other electronic device, but not your personal data. We will not be able to identity you personally.\n\n\
Most web browsers automatically accept cookies. You can configure your device's settings to reflect your preference to accept or reject cookies. However, if you reject all cookies, you will not be able to take full advantage of our Services.\n\n\
4. Governing law and jurisdiction\n\
This Agreement is governed by and shall be construed in accordance with the laws of Hong Kong.\n\n\
Both parties irrevocably agree that the courts of Hong Kong shall have the exclusive jurisdiction to determine any dispute arising out of or in connection with this Agreement.";

  constructor(props, context) {
    super(props, context);
    this.from = {
      "sex": "female",
      "companyName": null,
      "countryCode": "86",
      "industry_consumer": false,
      "industry_financial": false,
      "industry_healthcare": false,
      "industry_industrial": false,
      "industry_lifeSciences": false,
      "industry_technology": false,
      "package_50": false,
      "package_80": false,
      "package_100": false,
      "package_150": false,
    };
  }
  async componentDidMount() {
    if (this.props.sessionStore.token) {
      window.location = this.props.sessionStore.location.pathname;
    }
    if (Object.keys(this.props.sessionStore.User) === 0) {
      await this.props.sessionStore.logout();
      window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxdf647b9ecf173478&redirect_uri=https://wx.upro.link/wxauthredirect&response_type=code&scope=snsapi_base&state=/registration#wechat_redirect`;
    }
  }

  async handleSubmit() {
    var json;
    if (this.from.email || this.from.mobile) {
      json = await this.props.sessionStore.createUser(this.from);
    } else {
      this.displayAlert = true;
    }
    if (json) {
      await this.props.sessionStore.logout();
      window.location = qs.parse(this.props.routingStore.location.search)['?redirect'];
    }
  }

  handleChange(field) {
    if (field === "industry" || field === "package") {
      return (e) => {
        this.from[e.target.value] = e.target.checked;
      }
    } else {
      return (e) => {
        this.from[field] = e.target.value;
      }
    }
  }

  alert(props) {
    if (this.displayAlert) {
      return <span display={this.displayAlert} style={{color: "#F00"}}>Please fill in the required items above</span>
    }
  }

  render() {
    return (
      <div style={{backgroundColor: "#eee", paddingBottom:50}}>
        <div className="App-header">
          <h2>Registration</h2>
          Fill in your personal details and become one of our members.
        </div>
        <CellsTitle>Your personal details</CellsTitle>
        <Form>
          <FormCell>
            <Label>Company Name (if any)</Label>
            <Input onChange={this.handleChange('companyName')} type="text" />
          </FormCell>
          <FormCell>
            <Label>First Name</Label>
            <Input onChange={this.handleChange('firstName')} type="text" />
          </FormCell>
          <FormCell>
            <Label>Last Name</Label>
            <Input onChange={this.handleChange('lastName') } type="text" />
          </FormCell>
          <FormCell>
            <Label>Email</Label>
            <Input onChange={this.handleChange('email')} type="text" />
          </FormCell>
          <FormCell>
            <Label>WeChat</Label>
            <Input onChange={this.handleChange('wechat')} type="text" />
          </FormCell>
        </Form>
        <CellsTitle>Mobile country code</CellsTitle>
        <Form radio  onChange={this.handleChange('countryCode')}>
          <FormCell radio>
            <CellBody>Mainland China (+86)</CellBody>
            <CellFooter>
              <Radio name="radio2" value="86" defaultChecked/>
            </CellFooter>
          </FormCell>
          <FormCell radio>
            <CellBody>Hong Kong (+852)</CellBody>
            <CellFooter>
              <Radio name="radio2" value="852"/>
            </CellFooter>
          </FormCell>
        </Form>
        <Form>
          <FormCell>
            <Label>Mobile</Label>
            <Input onChange={this.handleChange('mobile')} type="text" />
          </FormCell>
        </Form>
        <Form radio onChange={this.handleChange('sex')}>
          <FormCell radio>
            <CellBody>Female</CellBody>
            <CellFooter>
              <Radio name="radio1" value="female" defaultChecked/>
            </CellFooter>
          </FormCell>
          <FormCell radio>
            <CellBody>Male</CellBody>
            <CellFooter>
              <Radio name="radio1" value="male"/>
            </CellFooter>
          </FormCell>
        </Form>
        <CellsTitle>Your interested job size (annual) in HKD (May choose more then one)</CellsTitle>
        <Form checkbox onChange={this.handleChange('package')}>
          <FormCell checkbox>
            <Checkbox value="package_50"/>
            <CellBody>&lt; $&nbsp;&nbsp;&nbsp;500,000 HKD</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="package_80"/>
            <CellBody>~ $&nbsp;&nbsp;&nbsp;800,000 HKD</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="package_100"/>
            <CellBody>~ $1,000,000 HKD</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="package_150"/>
            <CellBody>> $1,500,000 HKD</CellBody>
          </FormCell>
        </Form>

        <CellsTitle>Your target industries (May choose more then one)</CellsTitle>
        <Form checkbox onChange={this.handleChange('industry')}>
          <FormCell checkbox>
              <Checkbox value="industry_consumer"/>
              <CellBody>Consumer</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_financial"/>
            <CellBody>Financial</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_healthcare"/>
            <CellBody>Healthcare</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_industrial"/>
            <CellBody>Industrial</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_lifeSciences"/>
            <CellBody>Life Sciences</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_technology"/>
            <CellBody>Technology</CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Please read and agree the following terms</CellsTitle>
        <Form>
          <FormCell>
            <CellBody>
                <TextArea readOnly={true} rows={15} showCounter={false} style={{fontSize: 10, whiteSpace: "pre-line"}}>
                  {this.terms}
                </TextArea>
            </CellBody>
          </FormCell>
        </Form>
        <ButtonArea>
          {this.alert()}
          <Button onClick={() => this.handleSubmit()}>I agree the terms above</Button>
        </ButtonArea>
      </div>
    );
  }
}
