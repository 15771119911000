import React, { Component } from 'react';
import { inject } from 'mobx-react';
import {FormCell} from 'react-weui';
import {CellBody, Button} from 'react-weui';
import {Panel, PanelHeader, PanelBody, MediaBox} from 'react-weui';
import {NonCacheTabNav} from './NonCacheTabNav';
import './App.css';

@inject('sessionStore')
class JobPosterOnlyRestrict extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonPressed: false,
      loading: true
    }
  }

  async handleUpgradeAccount() {
    await this.setState({
      buttonPressed: true
    })
    const result = await this.props.sessionStore.submitPremissionRequest({ premissionName: 'post_job' });
    if (result) {
      await this.setState({
        loading: false 
      })
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "#eee", paddingBottom: 50 }}>
            <div className="App-header">
              <h2>Become a Job Poster</h2>
            </div>
            <div>
            </div>
            <FormCell>
              <CellBody style={{whiteSpace: "pre-line"}}>
                <br />
                <span style={{ fontSize: 20 }}>If you are interested to post job for candidate referral:</span>
                <br />
                <Panel>
                  <PanelHeader>
                    Referrer (Your current role)
                  </PanelHeader>
                  <PanelBody>
                    <MediaBox type="text">
                      <div className="features">✅ Check latest jobs in UPRO</div>
                      <div className="features">✅ Refer candidates to any jobs</div>
                      <div className="features">✅ Keep track of your candidiates from time to time</div>
                    </MediaBox>
                  </PanelBody>
                </Panel>
                <Panel>
                  <PanelHeader>
                    Poster
                  </PanelHeader>
                  <PanelBody>
                    <MediaBox type="text">
                      <div className="features">✅ Check latest jobs in UPRO</div>
                      <div className="features">✅ Refer candidates to any jobs</div>
                      <div className="features">✅ Submit jobs to UPRO</div>
                      <div className="features">✅ Get referral of candidates to your job</div>
                      <div className="features">✅ Keep track of your job and your candidiates from time to time</div>
                    </MediaBox>
                {!this.state.buttonPressed &&
                  <Button onClick={(e) => this.handleUpgradeAccount()}>Upgrade my profile</Button>
                }
                {this.state.buttonPressed && this.state.loading &&
                  <span>Please wait...</span>
                }
                {this.state.buttonPressed && !this.state.loading &&
                  <span>Your request is well received, we will contact you very soon, thanks.</span>
                }
                  </PanelBody>
                </Panel>
              </CellBody>
            </FormCell>
            <NonCacheTabNav />
      </div>
    );
  }
}

export default function TierMemberOnly(Comp, premissionName) {
  @inject('sessionStore')
  class HOC extends Component {
    constructor(props) {
      super(props);
    }

    async componentWillMount() {
      await this.props.sessionStore.refreshMembershipStatus();
    }

    render() {
      return (
        <div>
          {this.props.sessionStore.User[`premission_${premissionName}`] !== 1 &&
            <JobPosterOnlyRestrict />
          }
          {this.props.sessionStore.User[`premission_${premissionName}`] === 1 &&
            <Comp {...this.props} />
          }
        </div>
      );
    }
  };
  return HOC;
}