import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import './App.css'

import {Cell} from 'react-weui';
import {Cells, CellBody, CellFooter} from 'react-weui';
import {Tab} from 'react-weui'

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import {NonCacheTabNav} from './NonCacheTabNav';
import moment from 'moment';

@inject('routingStore')
class CandidateCells extends Component {
  render() {
    if (this.props.data.length) {
      return <Cells>{this.props.data.map(v =>
        <Cell onClick={() => this.props.routingStore.push(`/job/${v.jobId}/candidate/${v.id}`)} access>
          <CellBody>
            <b>{v.jobTitle} of {v.jobName}</b><br/><b>{v.title}</b><br/><span style={{fontSize:10}}>{v.created_at}</span>
          </CellBody>
          { !this.props.owner &&
          <CellFooter>
            { (!v.agreed_jobOwner && v.agreed_candidate && "Awaiting your agreement") }
            { (v.agreed_jobOwner && v.agreed_candidate && "Both party agreed") }
            { (v.agreed_jobOwner && !v.agreed_candidate && "Awaiting candidate agreement") }
            { (v.declined_jobOwner && "Rejected") }
            { (v.declined_candidate && "Candidate is declined") }
            <br />
            <span style={{fontSize: 10}}>Provider: {v.providerFirstName} {v.providerLastName}</span>
          </CellFooter>
          }
        </Cell>
      )}</Cells>
    } else {
      return <div style={{textAlign:"center", color:"#959595", padding: 20}}>No candidate yet</div>;
    }
  }
}

@inject('routingStore','sessionStore','jobStore','candidateStore')
@observer
export default class Candidates extends Component {

  constructor(props, context) {
    super(props, context);
  }

  getCandidates() {
    try {
      var candidates = toJS(this.props.candidateStore.candidates);
      var r = candidates.data.reduce((res, j, i) => {
        if (! j.isMine) {
          res.push({
            id: j.id,
            title: j.title,
            jobId: j.jobId,
            jobTitle: j.jobTitle,
            jobName: j.jobName,
            agreed_jobOwner: j.agreed_jobOwner,
            agreed_candidate: j.agreed_candidate,
            declined_jobOwner: j.declined_jobOwner,
            created_at: moment(j.created_at).utcOffset(8).format('D/M/YY, h:mm:ss a'),
            providerFirstName: j.providerFirstName,
            providerLastName: j.providerLastName
          })
        }
        return res;
      }, [])
      return r || [];
    } catch (err) {

    }
  }

  async componentWillMount() {
    var params = {jobOwner: true};
    await this.props.candidateStore.listCandidateOfJob({}, params);
  }

  render() {
    return (
      <div style={{backgroundColor: "#eee", paddingBottom: 50}}>
        <div className="App-header">
          <h2>My Received Referrals</h2>
          Check candidates that referred to you.
        </div>
        <Tab>
        <CandidateCells data={this.getCandidates()} />
        </Tab>
       <NonCacheTabNav />
      </div>
    );
  }
}
