import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './App.css'

import {Cell} from 'react-weui';
import {Cells, CellBody, CellFooter} from 'react-weui';

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import moment from 'moment';

@inject('routingStore')
@observer
export default class Profile extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div style={{backgroundColor: "#eee", paddingBottom: 50}}>
        <div className="App-header">
          <h2>Profile</h2>
          Edit profile and manage jobs
        </div>
        <Cells>
          <Cell onClick={() => this.props.routingStore.push('/settings')} access>
            <CellBody>
              Settings
            </CellBody>
            <CellFooter>
            </CellFooter>
          </Cell>
          <Cell onClick={() => this.props.routingStore.push('/job/new')} access>
            <CellBody>
              Post a new job
            </CellBody>
            <CellFooter>
            </CellFooter>
          </Cell>
          <Cell onClick={() => this.props.routingStore.push('/myjobs')} access>
            <CellBody>
              My Posted Jobs
            </CellBody>
            <CellFooter>
            </CellFooter>
          </Cell>
          <Cell onClick={() => this.props.routingStore.push('/received_referrals')} access>
            <CellBody>
              My Received Referrals
            </CellBody>
            <CellFooter>
            </CellFooter>
          </Cell>
        </Cells>
      </div>
    );
  }
}