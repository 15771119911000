import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {observable, toJS} from 'mobx';
import './App.css'
import location from '../shared/location';

import {Tab, TabBody, NavBar, NavBarItem, Toast} from 'react-weui'
import {Cells, Cell, CellBody, CellFooter} from 'react-weui'

import 'weui';
import 'react-weui/build/packages/react-weui.css';

@inject('routingStore')
class JobCells extends Component {
  range = [
    "< $   500,000 HKD",
    "~ $   800,000 HKD",
    "~ $ 1,000,000 HKD",
    "> $ 1,500,000 HKD"
  ];

  render() {
    if (this.props.loading) {
      return <div style={{height: 120}}>
          <Toast icon="loading" show={true}>Loading...</Toast>
        </div>
    }
    if (this.props.data.length) {
      return <Cells style={{lineHeight: 1.3}}>{this.props.data.map(v => {
        if (v.archived && this.props.mine || !v.archived) {
          return (
            <Cell onClick={e => { window.location.pathname = `/job/${v.id}` } } access>
              <CellBody>
                <div>{!!v.archived && <i>archived</i>}</div>
                <b>{v.name}</b><br/>
                <span style={{fontSize: 10}}>{v.company}</span>{v.company && <br/>}
                <span style={{fontSize: 10}}>{v.specialties}: {v.subSpecialties}</span>
              </CellBody>
              <CellFooter>
                {v.city}<br/><span style={{fontSize: 10}}>{this.range[v.salaryRange]}</span>
              </CellFooter>
            </Cell>
          )
      } else {
        return null;
      }
      }) }
      </Cells>
    } else {
      return null;
    }
  }
}


@inject('sessionStore', 'routingStore', 'jobStore')
@observer
export default class Jobs extends Component {

  @observable tab = 1;
  @observable userPreference = {};
  @observable loading = false;

  constructor(props, context) {
    super(props, context);
  }

  async componentWillMount() {
    await this.props.jobStore.getSpecialties();

    this.userPreference = {
      "specialties": [
        this.props.sessionStore.User.industry_consumer,
        this.props.sessionStore.User.industry_financial,
        this.props.sessionStore.User.industry_industrial,
        this.props.sessionStore.User.industry_healthcare,
        this.props.sessionStore.User.industry_lifeSciences,
        this.props.sessionStore.User.industry_technology,
      ],
      "salaryRange":
        [
          this.props.sessionStore.User.package_50,
          this.props.sessionStore.User.package_80,
          this.props.sessionStore.User.package_100,
          this.props.sessionStore.User.package_150,
        ]
    }

    await this.filter(this.userPreference);
  }

  async filter(preference = false, myJob = false) {
    var query = {};
    if (myJob) {
      query.me = true;
    }
    if (preference) {
     var specialties = preference.specialties.reduce( (result, item, index) => {
       if (!item) {
         result.push({ "specialties": { $ne: index } })
         return result
       } else {
         return result
       }
     }, []);
      var salaryRange = preference.salaryRange.reduce( (result, item, index) => {
        if (!item) {
          result.push({ "salaryRange": { $ne: index } })
          return result
        } else {
          return result
        }
      }, []);
      var $and = [].concat(specialties)
      $and = $and.concat(salaryRange)
      query = $and;
    }

    await this.props.jobStore.listJob(query);
  }

  getJobs() {
    try {
      var jobs = toJS(this.props.jobStore.jobs);
      var specialties = [];
      var r = jobs.data.map((j, i) => {
        specialties = this.props.jobStore.specialties.find(o => o.id === j.specialties);
        return {
          title: j.title,
          name: j.name,
          company: j.ownerCompany,
          specialties: specialties.name,
          subSpecialties: specialties.subSpecialties.find(o => o.id === j.subSpecialties).name,
          city: location.getLocationByAbb(j.city),
          salaryRange: j.salaryRange,
          id: j.id,
          archived: j.archived
        }
      })
      return r || [];
    } catch (err) {
    }
  }

 render() {
    if (this.props.jobStore.jobs) {
   return (<div>
     <div className="App-header">
       <h2>Jobs Available</h2>
       Refer your candidates
     </div>
     <Tab>
       <NavBar>
         <NavBarItem active={this.tab == 1} onClick={e=>{ this.filter(this.userPreference); this.tab = 1}}>Your interested jobs</NavBarItem>
         <NavBarItem active={this.tab == 2} onClick={e=>{ this.filter(); this.tab = 2}}>All jobs</NavBarItem>
       </NavBar>
       <TabBody style={{paddingBottom: 10}}>
         <JobCells data={this.getJobs()} mine={() => {this.tab === 1}} loading={this.props.jobStore.loading}/>
       </TabBody>
     </Tab>
   </div>)
    } else {
      return (<div>loading...</div>)
    }
 }
}
