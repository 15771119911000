import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {toJS} from 'mobx';
import './App.css'
import location from '../shared/location';

import {Tab, TabBody, NavBar, NavBarItem, Toast} from 'react-weui'
import {Cells, Cell, CellBody, CellFooter} from 'react-weui'

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import {NonCacheTabNav} from './NonCacheTabNav';

@inject('routingStore')
class JobCells extends Component {
  range = [
    "< $   500,000 HKD",
    "~ $   800,000 HKD",
    "~ $ 1,000,000 HKD",
    "> $ 1,500,000 HKD"
  ];

  render() {
    if (this.props.loading) {
      return <div style={{height: 130}}>
          <Toast icon="loading" show={true}>Loading...</Toast>
        </div>
    }
    if (this.props.data.length) {
      return <Cells style={{lineHeight: 1.3}}>{this.props.data.map(v => {
        return (
          <Cell onClick={() => this.props.routingStore.push(`/job/${v.id}`)} access>
            <CellBody>
              <div>{!!v.archived && <i>archived</i>}</div>
              <b>{v.name}</b><br />
              <span style={{ fontSize: 10 }}>{v.company}</span>{v.company && <br />}
              <span style={{ fontSize: 10 }}>{v.specialties}: {v.subSpecialties}</span>
            </CellBody>
            <CellFooter>
              {v.city}<br /><span style={{ fontSize: 10 }}>{this.range[v.salaryRange]}</span>
            </CellFooter>
          </Cell>
        )
      }) }
      </Cells>
    } else {
      return <div style={{textAlign:"center", color:"#959595", padding: 20}}><br />No job yet</div>;
    }
  }
}


@inject('sessionStore', 'routingStore', 'jobStore')
@observer
export default class MyJobs extends Component {

  constructor(props, context) {
    super(props, context);
  }

  async componentWillMount() {
    await this.props.jobStore.getSpecialties();

    this.userPreference = {
      "specialties": [
        this.props.sessionStore.User.industry_consumer,
        this.props.sessionStore.User.industry_financial,
        this.props.sessionStore.User.industry_industrial,
        this.props.sessionStore.User.industry_healthcare,
        this.props.sessionStore.User.industry_lifeSciences,
        this.props.sessionStore.User.industry_technology,
      ],
      "salaryRange":
        [
          this.props.sessionStore.User.package_50,
          this.props.sessionStore.User.package_80,
          this.props.sessionStore.User.package_100,
          this.props.sessionStore.User.package_150,
        ]
    }

    await this.props.jobStore.listJob({me: true});
  }

  getJobs () {
    try {
      var jobs = toJS(this.props.jobStore.jobs);
      var specialties = [];
      var r = jobs.data.map((j, i) => {
        specialties = this.props.jobStore.specialties.find(o => o.id === j.specialties);
        return {
          title: j.title,
          name: j.name,
          company: j.ownerCompany,
          specialties: specialties.name,
          subSpecialties: specialties.subSpecialties.find(o => o.id === j.subSpecialties).name,
          city: location.getLocationByAbb(j.city),
          salaryRange: j.salaryRange,
          id: j.id,
          archived: j.archived
        }
      })
      return r || [];
    } catch (err) {

    }
  }

 render() {
    if (this.props.jobStore.jobs) {
   return (<div>
     <div className="App-header">
       <h2>You Posted Jobs</h2>
       Show all your posted jobs
     </div>
     <Tab>
       <NavBar>
         <NavBarItem onClick={e=>{ this.props.routingStore.push('/job/new') }}>Post a new job</NavBarItem>
       </NavBar>
       <TabBody>
         <JobCells data={this.getJobs()} loading={this.props.jobStore.loading}/>
       </TabBody>
     </Tab>
       <NonCacheTabNav />
   </div>)
    } else {
      return (<div>loading...</div>)
    }
 }
}
