import {observable, action} from 'mobx';
import api from '../shared/api';

class InvitationStore {

  constructor() {
  }

  @action('invitation: verify invitation code') async verifyInvitationCode(code) {
    try {
      const json = await api.create(`invitation-code`, {code: code})
      return Promise.resolve(json)
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new InvitationStore();
