// helper - locations

class Locations {
  cities = [];
  constructor() {
    this.cities["beijing"] = "Beijing 北京";
    this.cities["chongqing"] = "Chongqing 重庆";
    this.cities["shanghai"] = "Shanghai 上海";
    this.cities["tianjin"] = "Tianjin 天津";
    this.cities["hong"] = "Hong Kong 香港";
    this.cities["macau"] = "Macau 澳门";
    this.cities["taiwan"] = "Taiwan 台湾";
    this.cities["anhui"] = "Anhui 安徽";
    this.cities["fujian"] = "Fujian 福建";
    this.cities["gansu"] = "Gansu 甘肃";
    this.cities["guangdong"] = "Guangdong 广东";
    this.cities["guizhou"] = "Guizhou 贵州";
    this.cities["hainan"] = "Hainan 海南";
    this.cities["hebei"] = "Hebei 河北";
    this.cities["heilongjiang"] = "Heilongjiang 黑龙江";
    this.cities["henan"] = "Henan 河南";
    this.cities["hubei"] = "Hubei 湖北";
    this.cities["hunan"] = "Hunan 湖南";
    this.cities["jiangsu"] = "Jiangsu 江苏";
    this.cities["jiangxi"] = "Jiangxi 江西";
    this.cities["jilin"] = "Jilin 吉林";
    this.cities["liaoning"] = "Liaoning 辽宁";
    this.cities["qinghai"] = "Qinghai 青海";
    this.cities["shaanxi"] = "Shaanxi 陕西";
    this.cities["shandong"] = "Shandong 山东";
    this.cities["shanxi"] = "Shanxi 山西";
    this.cities["sichuan"] = "Sichuan 四川";
    this.cities["yunnan"] = "Yunnan 云南";
    this.cities["zhejiang"] = "Zhejiang 浙江";
    this.cities["guangxi"] = "Guangxi Zhuang 广西壮族";
    this.cities["inner"] = "Inner Mongolia 内蒙古";
    this.cities["ningxia"] = "Ningxia Hui 宁夏回族";
    this.cities["xinjiang"] = "Xinjiang Uighur 新疆维吾尔族";
    this.cities["tibet"] = "Tibet 西藏";
  }

  getLocationByAbb(abb) {
    return this.cities[abb];
  }

  getLocationForWxForm() {
    return Object.keys(this.cities).map(
      i => { return { label: this.cities[i], value: i, key: i } }
    );
  }
}

export default new Locations();
