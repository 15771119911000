import sessionStore from '../stores/sessionStore'
import qs from 'qs'

class Api {

  constructor({url}) {
    this.apiUrl = url;
  }

  async login(credential) {
    return await this.call(`authentication`, credential, 'POST')
  }

  async fetch(endpoint, query = {}) {
    return await this.call(endpoint, query, 'GET')
  }

  async create(endpoint, data) {
    return await this.call(endpoint, data, 'POST')
  }

  async update(endpoint, data) {
    return await this.call(endpoint, data, 'PUT')
  }

  async patch(endpoint, data) {
    return await this.call(endpoint, data, 'PATCH')
  }

  async delete(endpoint, data) {
    return this.call(endpoint, data, 'DELETE')
  }

  async call(endpoint, data, method) {
    var param = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      }
    }
    console.log(sessionStore.token)
    if (sessionStore.token) {
      param.headers.Authorization = `Bearer ${sessionStore.token}`
    }
    if (method !== 'GET') {
      param.body = JSON.stringify(data)
    }
    var url = method === 'GET' ? `${endpoint}?${qs.stringify(data)}` : endpoint
    console.log('API', method, url)
    try {
      const res = await fetch(this.apiUrl + '/' + url, param)
      console.log(res);
      const json = await res.json()
      if (res.status === 200 || res.status === 201 || res.status === 401) {
        return Promise.resolve(json)
      } else {
        // handle auto logout for auth error
        return Promise.reject(json || 'Please try again later.')
      }
    } catch (error) {
      return Promise.reject(error, 'Please try again later.')
    }
  }

}

export default new Api({url : process.env.API_URL || window.env.API_URL});
