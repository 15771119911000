import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import './App.css';

import {Form, FormCell} from 'react-weui';
import {Label, Button, TextArea, Select} from 'react-weui';
import {CellsTitle, CellBody} from 'react-weui';

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import {NonCacheTabNav} from './NonCacheTabNav';

@inject('sessionStore', 'routingStore', 'candidateStore', 'jobStore')
@observer
export default class Candidate_get extends Component {

  @observable jobId;
  @observable candidateId;
  @observable candidate = {};
  @observable form;

  constructor(props, context) {
    super(props, context);
    this.jobId = /[^job/]+/.exec(this.props.routingStore.location.pathname)[0];
    this.candidateId = (/candidate\/(.*?)\/+/.exec(this.props.routingStore.location.pathname)[1]);
    this.form = {
      status: 'presenting'
    };
  }

  async componentWillMount() {
    var params = {};
    await this.props.jobStore.showJob(this.jobId);
    if (!!this.props.jobStore.currentJob.isMine) {
      params.jobOwner = true;
    }
    await this.props.candidateStore.showCandidate(this.candidateId, params);
    this.candidate = toJS(this.props.candidateStore.currentCandidate);
  }

  async handleSubmit() {
    const json = await this.props.candidateStore.updateStatus(this.candidateId, this.form);
    if (json) {
      window.location = `/job/${this.jobId}/candidate/${this.candidateId}`
    }
  }

  handleChange(field) {
    return (e) => {
      if (this.form[field] !== e.target.value)
        this.form[field] = e.target.value;
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "#eee", paddingBottom: 50 }}>
        <div className="App-header">
          <h2>Candidate Status Update</h2>
          Candidate for {this.props.jobStore.currentJob.name} {this.props.jobStore.currentJob.title}
        </div>
        <div>
          <CellsTitle>Candidate</CellsTitle>
          <Form>
          <FormCell>
             <Label>First Name</Label>
             { this.candidate.first_name }
           </FormCell>
           <FormCell>
             <Label>Last Name</Label>
             { this.candidate.last_name }
           </FormCell>
          </Form>
          <CellsTitle>Candidate Status Update</CellsTitle>
          <Form>
            <FormCell select selectPos="after">
              <Label>Status</Label>
              <CellBody>
                <Select data={[
                  {
                    value: 'presenting',
                    label: 'Presenting'
                  },
                  {
                    value: 'interview_1',
                    label: '1st interview'
                  },
                  {
                    value: 'interview_2',
                    label: '2nd interview'
                  },
                  {
                    value: 'interview_3',
                    label: '3th interview'
                  },
                  {
                    value: 'rejected',
                    label: 'Rejected'
                  },
                  {
                    value: 'hired',
                    label: 'Hired'
                  }
                ]} onChange={this.handleChange('status')}/>
              </CellBody>
            </FormCell>
            <FormCell>
              <Label>Remark</Label>
              <TextArea onChange={this.handleChange('info')} type="text" />
            </FormCell>
          </Form>
          <Button onClick={() => this.handleSubmit()}>Add a new update</Button>
        </div>
       <NonCacheTabNav />
      </div>
    )
  }
}
