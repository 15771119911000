import {observable, action} from 'mobx';
import api from '../shared/api';

class CandidateStore {

  @observable currentCandidate = {};
  @observable candidates = { data : [] };
  @observable loading = false;

  constructor() {
  }

  @action('Candidate: new candidate for a job') async createCandidate(candidate) {
    try {
      const json = await api.create(`candidates`, candidate)
      return Promise.resolve(json)
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @action('Candidate: poster agree the terms from a referrer of such candidate') async acceptTerms(candidate_id) {
    try {
      const json = await api.patch(`candidates`, candidate_id);
      console.log(json)
      this.candidates = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('Candidate: show all candidate from one job') async listCandidateOfJob(job, query = {}) {
    this.loading = true;
    try {
      query.jobId = job;
      const json = await api.fetch(`candidates`, query);
      console.log(json)
      this.candidates = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('candidate: poster reject a candidate') async rejectCandidate(id) {
    this.loading = true;
    try {
      const json = await api.patch(`candidates/${id}`, {declined_jobOwner: true});
      this.loading = false;
      return Promise.resolve(json)
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('candidate: poster accept a candidate') async acceptCandidate(id) {
    this.loading = true;
    try {
      const json = await api.patch(`candidates/${id}`, {agreed_jobOwner: true});
      console.log(json)
      this.loading = false;
      return Promise.resolve(json)
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('candidate: show one Job') async showCandidate(id, query = {}) {
    try {
      const json = await api.fetch(`candidates/${id}`, query);
      this.currentCandidate = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('candidate: add a new status update to candidate') async updateStatus(id, data) {
    try {
      const json = await api.create(`candidate/status`, {candidateId: id, status: data.status, info: data.info});
      this.loading = false;
      return Promise.resolve(json)
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }
}

export default new CandidateStore();
