import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import './App.css'

import {Form, FormCell} from 'react-weui';
import {Label, Button} from 'react-weui';
import {CellHeader, CellsTitle, CellBody} from 'react-weui';
import {Tab, NavBar, NavBarItem} from 'react-weui'
import {Icon} from 'antd'

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import moment from 'moment';
import {NonCacheTabNav} from './NonCacheTabNav';
import locations from '../shared/location';

@inject('routingStore', 'sessionStore','jobStore','uploadStore')
@observer
export default class Job_get extends Component {

  @observable tab;
  @observable jobId;
  @observable file;
  @observable noOrginalJobEditPopUp = false;

  constructor(props, context) {
    super(props, context);
  }

  async componentWillMount() {
    this.jobId = /[^\/]+$/.exec(this.props.routingStore.location.pathname)[0];
    await this.props.jobStore.showJob(this.jobId);
    await this.props.jobStore.getSpecialties();
    await this.props.jobStore.getFunctions();
    if (this.props.jobStore.currentJob.upload) {
      this.file = await this.props.uploadStore.getUpload({ code: this.props.jobStore.currentJob.upload });
    }
  }

  getSalaryRange() {
    try {
      var range = [
        { label: "< $   500,000 HKD" },
        { label: "~ $   800,000 HKD" },
        { label: "~ $ 1,000,000 HKD" },
        { label: "> $ 1,500,000 HKD" }
      ];
      return range[this.props.jobStore.currentJob.salaryRange].label;
    } catch (err) {

    }
  }

  jobSpecialties() {
    try {
      if (this.props.jobStore.specialties && this.props.jobStore.currentJob) {
        const id = toJS(this.props.jobStore.currentJob.specialties)
        const special = toJS(this.props.jobStore.specialties[id - 1]);
        return special.name
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {

    }
  }

  jobSubSpecialties() {
    try {
      if (this.props.jobStore.specialties && this.props.jobStore.currentJob) {
        const jsid = toJS(this.props.jobStore.currentJob.specialties);
        const id = toJS(this.props.jobStore.currentJob.subSpecialties);
        const special = toJS(this.props.jobStore.specialties[jsid - 1]);
        return special.subSpecialties[id - special.subSpecialties[0].id].name
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {

    }
  }

  jobFunctions() {
    try {
      if (this.props.jobStore.functions) {
        const id = toJS(this.props.jobStore.currentJob.function);
        const functions = toJS(this.props.jobStore.functions[id - 1]);
        return functions.name;
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {

    }
  }

  getTitle() {
    try {
      var title = toJS(this.props.jobStore.currentJob.title);
      return title[0].toUpperCase()+title.slice(1);
    } catch (err) {

    }
  }

  getDate() {
    try {
      const date = moment(this.props.jobStore.currentJob.created_at).utcOffset(8);
      return date.format('MMMM Do YYYY, h:mm a');
    } catch (err) {

    }
  }

  async editJob() {
    try {
      if (await this.props.jobStore.shouldEditOrginalJob(this.jobId)) {
        this.props.routingStore.push(`/job/${this.jobId}/edit`);
      } else {
        this.noOrginalJobEditPopUp = true;
      }
    } catch (err) {

    }
  }

 render() {
   return (
     <div style={{backgroundColor: "#eee", paddingBottom:50}}>
     <div className="App-header">
       <h2>{ this.props.jobStore.currentJob.name }</h2>
       { this.props.jobStore.currentJob.ownerCompany && <h3><i>By { this.props.jobStore.currentJob.ownerCompany }</i></h3> }
       <span style={{fontSize: 12}}>Created at { this.getDate() }</span>
     </div>
       <div style={{padding: 20, display: this.props.jobStore.currentJob.archived? '': 'none'}}>
         This job is being archived.
         <Button onClick={(e) => {this.props.routingStore.push(`/job/${this.props.jobStore.currentJob.toNewJobId}`); this.props.routingStore.go() }}>Go to new version of the job</Button>
       </div>
       <Tab>
       <NavBar style={{display: this.props.jobStore.currentJob.isMine? '': 'none'}}>
         <NavBarItem style={{display: this.props.jobStore.currentJob.archived? 'none': ''}} active={this.tab == 0} onClick={e=>{ location.pathname = `/opportunity/${this.jobId}`; this.tab = 0}}>Share to<br/>candidates</NavBarItem>
         <NavBarItem style={{display: this.props.jobStore.currentJob.archived? 'none': '', paddingTop: 26}} active={this.tab == 1} onClick={e=>{ this.editJob(); this.tab = 1}} >Edit</NavBarItem>
         <NavBarItem active={this.tab == 2} onClick={e=>{ this.props.routingStore.push(`/job/${this.jobId}/candidates`); this.tab = 2}} style={{paddingTop: 26}}>Candidate ({this.props.jobStore.currentJob.candidateCount})</NavBarItem>
       </NavBar>
         <NavBar style={{display: this.props.jobStore.currentJob.isMine? 'none': ''}}>
           <NavBarItem style={{display: this.props.jobStore.currentJob.archived? 'none': ''}} active={this.tab == 0} onClick={e=>{ location.pathname = `/opportunity/${this.jobId}`; this.tab = 0}}>Share to candidates</NavBarItem>
           <NavBarItem active={this.tab == 1} onClick={e=>{ this.props.routingStore.push(`/job/${this.jobId}/candidates`); this.tab = 1}}>Recommend Candidate</NavBarItem>
         </NavBar>
       <br /><br />
       <br style={{display: this.props.jobStore.currentJob.isMine? '': 'none'}}/>
       {this.noOrginalJobEditPopUp && <div style={{padding: 5, backgroundColor: "#FFF"}}>This Job has candidate recommended based on the current terms.  If you edit it, candidate(s) recommended here will be archived with this job.  A new job will be created with your change and the referrer will be notified to recommended again. <Button onClick={() => this.props.routingStore.push(`/job/${this.jobId}/edit`)}>OK</Button><Button onClick={()=> this.noOrginalJobEditPopUp = false}>Cancel</Button></div>}
       <Form>
         <FormCell>
           <Label>Job Name/Title</Label>
           { this.props.jobStore.currentJob.name }
         </FormCell>
         {/* <FormCell>
           <CellHeader>
             <Label>Title</Label>
           </CellHeader>
           <CellBody>
            { this.getTitle() }
           </CellBody>
         </FormCell> */}
       </Form>
       <CellsTitle>&nbsp;</CellsTitle>
       <Form>
         <FormCell>
           <CellHeader>
             <Label>Industry</Label>
           </CellHeader>
           <CellBody>
             { this.jobSpecialties() }
           </CellBody>
         </FormCell>
         <FormCell>
           <CellHeader>
             <Label>Specialties</Label>
           </CellHeader>
           <CellBody>
             { this.jobSubSpecialties() }
           </CellBody>
         </FormCell>
         <FormCell>
           <CellHeader>
             <Label>Function</Label>
           </CellHeader>
           <CellBody>
             { this.jobFunctions() }
           </CellBody>
         </FormCell>
       </Form>
       <CellsTitle>Job Location</CellsTitle>
       <Form>
         <FormCell>
           <CellHeader>
             <Label>Province/City</Label>
           </CellHeader>
           <CellBody>
             { locations.getLocationByAbb(this.props.jobStore.currentJob.city) }
           </CellBody>
         </FormCell>
         <FormCell>
           <Label>Detailed Job Location</Label>
           { this.props.jobStore.currentJob.address || 'Not applicable.' }
         </FormCell>
       </Form>
       <CellsTitle>Highlight(s)</CellsTitle>
       <Form>
         <FormCell>
           <CellBody style={{whiteSpace: "pre-line"}}>
             { this.props.jobStore.currentJob.highlight || 'Not applicable.' }
           </CellBody>
         </FormCell>
       </Form>
       <CellsTitle>Details</CellsTitle>
       <Form>
         <CellsTitle>Job Description</CellsTitle>
         <FormCell>
           <CellBody style={{whiteSpace: "pre-line"}}>
             { this.props.jobStore.currentJob.jobDescription || 'Not applicable.' }
           </CellBody>
         </FormCell>
        <CellsTitle>Qualifications</CellsTitle>
         <FormCell>
           <CellBody style={{whiteSpace: "pre-line"}}>
             { this.props.jobStore.currentJob.qualifications || 'Not applicable.' }
           </CellBody>
         </FormCell>
       </Form>
       <CellsTitle>Salary package in HKD</CellsTitle>
       <Form>
         <FormCell>
           <CellBody>
             { this.getSalaryRange() }
           </CellBody>
         </FormCell>
       </Form>
       <CellsTitle>Terms with partners</CellsTitle>
       <Form>
         <FormCell>
           <Label>Client Fee (Optional)</Label>
           { this.props.jobStore.currentJob.clientFee || 'Not applicable.' }
         </FormCell>
         <FormCell>
             <Label>Rewards</Label>
             { this.props.jobStore.currentJob.split || 'Not applicable.' }
         </FormCell>
         <FormCell>
           <Label>Terms</Label>
           <CellBody style={{whiteSpace: "pre-line"}}>
             { this.props.jobStore.currentJob.terms || 'Not applicable.' }
           </CellBody>
         </FormCell>
       </Form>
       </Tab>
       <NonCacheTabNav />
 </div>
 )
 }
}