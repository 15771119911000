import React, { Component } from 'react'
import { Upload } from 'antd'
import 'antd/lib/upload/style/index.css';
import 'antd/lib/style/index.css';
import {
  Alert,
  Button
} from 'react-weui'
import axios from 'axios';

export default class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.upload = this.upload.bind(this)
    this.onRemove = this.onRemove.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      fileList: [],
      alertFailure: false,
      alertSuccess: false
    }
    this.uploadImage = async (data) => {
      const { file } = data
      var fileData = new FormData();
      fileData.append('files', file);
      const response = await axios.post(`${this.props.apiAddress}/assets`, fileData, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (response.data.success) {
        const imageUrl = response.data.file
        const currentFileList = (this.props.multiple && this.state.fileList) || []
        const updatedFileList = [...currentFileList, {
          uid: currentFileList.length,
          name: imageUrl.split('/').slice(-1)[0],
          status: 'done',
          url: imageUrl,
          thumbUrl: imageUrl,
        }]
        this.uploadImage && this.setState({
          fileList: updatedFileList,
        })
        const imageUrls = updatedFileList.map(item => item.url).join(',')
        this.uploadImage && this.props.onChange && this.props.onChange(imageUrls)
        data.onSuccess()
        this.uploadImage && this.setState({alertSuccess: true, alertFailure: false})
      } else {
        this.uploadImage && this.props.onFailure && this.props.onFailure()
        data.onError()
        this.uploadImage && this.setState({alertFailure: true, alertSuccess: false})
      }
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   const { value } = nextProps
  //   const imageUrls = (value && value.split(',')) || null
  //   const fileList = (imageUrls && imageUrls.map((url, index) => ({
  //     uid: index,
  //     name: url.split('/').slice(-1)[0],
  //     status: 'done',
  //     url,
  //     thumbUrl: url,
  //   })))
  //   this.setState({
  //     fileList,
  //   })
  // }

  componentWillUnmount() {
    this.setState({
       fileList: [],
    })
    this.uploadImage = null
  }

  onChange(info) {
    const { status } = info.file
    if (status === 'error') {
      this.setState({alertFailure: true, alertSuccess: false})
    }
  }

  onRemove(file) {
    const fileList = this.state.fileList.filter(item => item.uid !== file.uid)
    this.setState({
      fileList,
    })
    const imageUrls = (fileList.length > 0 && fileList.map(item => item.url).join(',')) || null
    this.props.onChange && this.props.onChange(imageUrls)
  }

  upload(data) {
    this.uploadImage(data)
  }

  render() {
    const props = {
      listType: 'picture',
      onChange: this.onChange,
      onRemove: this.onRemove,
      customRequest: this.upload,
      fileList: this.state.fileList || [],
    }
    return (
      <div>
        { this.props.noPreview &&
        <style jsx global>{`
          .ant-upload-list-item-done {
            display: none;
          }
        `}</style>
        ||
        <style jsx global>{`
        .app-image-upload img {
            object-fit: cover;
        }
        `}</style>
        }
        <Upload className='app-image-upload' {...props}>
          {this.state.alertSuccess &&
            <Alert color="info">
              File is uploaded.  Remeber confirm your upload.
            </Alert>
          }
          {this.state.alertFailure &&
            <Alert color="warning">
              Please upload the image again.
            </Alert>
          }
          <Button>
            {this.state.alertSuccess && "Click to upload again"}
            {!this.state.alertSuccess && "Click to upload"}
          </Button>
        </Upload>
      </div>
    )
  }
}
