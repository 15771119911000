import React from 'react'
import { Router, Switch, Route } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx';
import { syncHistoryWithStore } from 'mobx-react-router'
import qs from 'qs'
import 'typeface-noto-sans'
import 'typeface-montserrat'

import TierMemberOnly from './components/TierMemberOnly'
import App from './components/App.jsx'
import Registration from './components/Registration'
import UserEdit from './components/User_edit'
import Jobs from './components/Jobs'
import EditJob from './components/Job_edit'
import DisplayJob from './components/Job_get'
import JobPublic from './components/Job_get.public'
import Welcome from './components/Welcome'
import DisplayCandidate from './components/Candidates'
import CreateCandidate from './components/Candidate_create'
import ShowCandidate from './components/Candidate_get'
import CandidateConfirmation from './components/Candidate_Confirmation_get'
import CandidateStatusUpdate from './components/Candidate_status_update'
import Referrals from './components/Candidates'
import ReceivedReferrals from './components/ReceivedReferrals'
import News from './components/News'
import MyJobs from './components/MyJobs'
import Uploads from './components/Uploads'

const browserHistory = createBrowserHistory();

const WelcomeBack = (props) => (
  <div style={{ paddingTop: '20%', textAlign: 'center' }}>
    <style>{`
      body{
        background: #132546;
      }
      img {
        width: 120px;
      }
      .App-logo {
        padding-top: 0;
      }
    `}</style>
    {props.empty &&
    <style>{`
      @-webkit-keyframes show {
        0% {opacity: 0.5}
      }
      @keyframes show {
        0% {opacity: 0.5}
      }
      @-webkit-keyframes mymove {
        from {margin-top: 20px; opacity: 0.5}
        to {margin-top: 0px;}
      }
      @keyframes mymove {
        from {margin-top: 20px; opacity: 0.5}
        to {margin-top: 0px;}
      }
      img {
        -webkit-animation: show 1s;
        animation: show 1s;
      }
      .App-logo {
        -webkit-animation: mymove 1s;
        animation: mymove 1s;
      }
    `}</style>
    }
    <img alt="UPRO" src="/upro-logo-hero.png" />
    <div className={'App-logo'}>UPRO</div>
    <span style={{ color: '#c1c0c6' }}>{props.empty ? '': 'Welcome back'}</span>
  </div>
)

@inject('routingStore', 'sessionStore', 'wxStore')
@observer
export default class Routes extends React.Component {

  location = '';
  state = { loading: true };

  constructor(props, context) {
      super(props, context);
      this.history = syncHistoryWithStore(browserHistory, props.routingStore)
  }
  async componentWillMount() {
    this.location = toJS(this.props.routingStore.location);
    if (this.location.pathname.substring(0, 31) == "/MP_verify_AQkRzLdhtbogDbCe.txt") {
      console.log('success');
    } else if (this.location.pathname.substring(0, 8) === "/uploads") {
      console.log('success');
    } else if (this.location.pathname.substring(0, 12) === "/opportunity") {
      console.log('success');
    } else if (this.location.pathname.substring(0, 6) === "/cconf"){
      console.log('success');
    } else if (this.location.pathname.substring(0, 13) === "/registration") {
      console.log('success');
    } else if (this.props.routingStore.location.pathname === "/logout") {
      await this.props.sessionStore.logout();
      alert('logout successful');
    } else if (this.props.routingStore.location.pathname === "/wxauthredirect") {
      await this.props.sessionStore.login(qs.parse(this.props.routingStore.location.search)['?code']);
      window.location = qs.parse(this.props.routingStore.location.search)['state']
    } else if (!this.props.sessionStore.wxOpenId) {
      window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf8bebe3ef17df5d2&redirect_uri=https://wx.${process.env.UPRO_ENV === 'dev' || ( window.env && window.env.UPRO_ENV === 'dev') ? 'dev.' : ''}upro.link/wxauthredirect&response_type=code&scope=snsapi_base&state=${this.props.routingStore.location.pathname}#wechat_redirect`;
    } else if (this.props.routingStore.location.pathname !== "/unauthorized" && Object.keys(this.props.sessionStore.User).length === 0) {
      window.location = `/unauthorized`
    } else if (this.props.routingStore.location.pathname === '/unauthorized') {
      if (Object.keys(this.props.sessionStore.User).length !== 0) {
        window.location = `/`;
      }
    }
  }
  async componentDidMount() {
    this.setState({ loading: false })
  }

  render() {
    if (this.state.loading) {
      return <WelcomeBack empty />
    }
    let router = (
      <Router history={this.history}>
        <Switch>
          <Route path="/MP_verify_AQkRzLdhtbogDbCe.txt" component={'AQkRzLdhtbogDbCe'} />
          <Route path="/index" component={App} />
          <Route path="/wxauthredirect" component={WelcomeBack} />
          <Route path="/registration" component={Registration} />
          <Route path="/received_referrals" component={ReceivedReferrals} />
          <Route path="/settings" component={UserEdit} />
          <Route path="/myjobs" component={TierMemberOnly(MyJobs, 'post_job')} />
          <Route path="/jobs" component={Jobs} />
          <Route path="/job/:id/candidates" component={DisplayCandidate} />
          <Route path="/job/:id/candidate/:cid/status_update" component={CandidateStatusUpdate} />
          <Route path="/job/:id/candidate/new" component={CreateCandidate} />
          <Route path="/job/:id/candidate/:cid" component={ShowCandidate} />
          <Route path="/job/:id/edit" component={TierMemberOnly(EditJob, 'post_job')} />
          <Route path="/job/new" component={TierMemberOnly(EditJob, 'post_job')} />
          <Route path="/job/:id" component={DisplayJob} />
          <Route path="/referrals" component={Referrals} />
          <Route path="/profile" component={UserEdit} />
          <Route path='/logout' component={App} />
          <Route path='/news' component={News} />
          <Route path='/unauthorized' component={Welcome} />
          <Route component={App} />
        </Switch>
      </Router>
    )
    if (this.location.pathname.substring(0, 31) == "/MP_verify_AQkRzLdhtbogDbCe.txt"){
      return 'AQkRzLdhtbogDbCe';
    } else if (this.location.pathname.substring(0, 12) === "/opportunity") {
      return <JobPublic/>
    } else if (this.location.pathname.substring(0, 6) === "/cconf"){
      return <CandidateConfirmation id={this.location.pathname.substring(7)}/>
    } else if (this.location.pathname.substring(0, 8) === "/uploads"){
      return <Uploads code={this.location.pathname.substring(9)}/>
    } else if (this.props.sessionStore.wxOpenId) {
      return router
    } else if (this.location.pathname.substring(0, 13) === "/registration") {
      return router
    } else {
      return <WelcomeBack empty />;
    }
  }
}