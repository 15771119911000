import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import './App.css'
import {Form, FormCell} from 'react-weui';
import {Input, Label, Button, ButtonArea, Radio, Checkbox} from 'react-weui';
import {CellsTitle, CellBody, CellFooter} from 'react-weui';

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import {NonCacheTabNav} from './NonCacheTabNav';

@inject('routingStore', 'sessionStore')
@observer
export default class UserEdit extends Component {

  @observable displayAlert = false;
  @observable displaySuccess = false;
  @observable User = {};

  constructor(props, context) {
    super(props, context);
    this.from = {};

  }
//  async componentDidMount() {
//  }

  async handleSubmit() {
    console.log(this.from)
    if (Object.keys(this.from).length !== 0) {
      var json;
      if (!this.from.email || this.from.email !== "" && !this.from.mobile && this.from.mobile !== "") {
        json = await this.props.sessionStore.patchUser(this.from);
        this.props.routingStore.push('/')
      } else {
        this.displayAlert = true;
      }
      if (json) {
        this.displaySucess = true;
      }
    } else {
      this.props.routingStore.push('/')
    }
  }

  handleChange(field) {
    if (field === "industry" || field === "package") {
      return (e) => {
        this.from[e.target.value] = e.target.checked;
      }
    } else {
      return (e) => {
        this.from[field] = e.target.value;
      }
    }
  }

  alert(props) {
    if (this.displayAlert) {
      return <span display={this.displayAlert} style={{color: "#F00"}}>Please fill in the required items above</span>
    }
  }

  success(props) {
      if (this.displaySuccess) {
        return <span display={this.displaySuccess} style={{color: "#0F0"}}>Updated</span>
      }
  }

  render() {
    return (
      <div style={{backgroundColor: "#eee", paddingBottom:50}}>
        <div className="App-header">
          <h2>Settings</h2>
          Review and edit your preference and your profile.
        </div>
        <CellsTitle>Your personal details</CellsTitle>
        <Form>
          <FormCell>
            <Label>Company<br/>Name (if any)</Label>
            <Input onChange={this.handleChange('companyName')} defaultValue={this.props.sessionStore.User.companyName} type="text" />
          </FormCell>
          <FormCell>
            <Label>First Name</Label>
            <Input onChange={this.handleChange('firstName')} defaultValue={this.props.sessionStore.User.firstName} type="text" />
          </FormCell>
          <FormCell>
            <Label>Last Name</Label>
            <Input onChange={this.handleChange('lastName') } defaultValue={this.props.sessionStore.User.lastName} type="text" />
          </FormCell>
          <FormCell>
            <Label>Email</Label>
            <Input onChange={this.handleChange('email')} defaultValue={this.props.sessionStore.User.email} type="text" />
          </FormCell>
          <FormCell>
            <Label>WeChat</Label>
            <Input onChange={this.handleChange('wechat')} defaultValue={this.props.sessionStore.User.wechat} type="text" />
          </FormCell>
        </Form>
        <CellsTitle>Mobile country code</CellsTitle>
        <Form radio  onChange={this.handleChange('countryCode')}>
          <FormCell radio>
            <CellBody>Mainland China (+86)</CellBody>
            <CellFooter>
              <Radio name="radio2" value="86" defaultChecked={this.props.sessionStore.User.countryCode === '86'}/>
            </CellFooter>
          </FormCell>
          <FormCell radio>
            <CellBody>Hong Kong (+852)</CellBody>
            <CellFooter>
              <Radio name="radio2" value="852" defaultChecked={this.props.sessionStore.User.countryCode === '852'}/>
            </CellFooter>
          </FormCell>
        </Form>
        <Form>
          <FormCell>
            <Label>Mobile</Label>
            <Input onChange={this.handleChange('mobile')} defaultValue={this.props.sessionStore.User.mobile} type="text" />
          </FormCell>
        </Form>
        <Form radio  onChange={this.handleChange('sex')}>
          <FormCell radio>
            <CellBody>Female</CellBody>
            <CellFooter>
              <Radio name="radio1" value="female" defaultChecked={this.props.sessionStore.User.sex === 'female'}/>
            </CellFooter>
          </FormCell>
          <FormCell radio>
            <CellBody>Male</CellBody>
            <CellFooter>
              <Radio name="radio1" value="male" defaultChecked={this.props.sessionStore.User.sex === 'male'}/>
            </CellFooter>
          </FormCell>
        </Form>
        <CellsTitle>Your interested job value (annual) in HKD</CellsTitle>
        <Form checkbox onChange={this.handleChange('package')}>
          <FormCell checkbox>
            <Checkbox value="package_50" defaultChecked={this.props.sessionStore.User.package_50} />
            <CellBody>&lt; $&nbsp;&nbsp;&nbsp;500,000 HKD</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="package_80" defaultChecked={this.props.sessionStore.User.package_80} />
            <CellBody>~ $&nbsp;&nbsp;&nbsp;800,000 HKD</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="package_100" defaultChecked={this.props.sessionStore.User.package_100} />
            <CellBody>~ $1,000,000 HKD</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="package_150" defaultChecked={this.props.sessionStore.User.package_150} />
            <CellBody>> $1,500,000 HKD</CellBody>
          </FormCell>
        </Form>

        <CellsTitle>Your expertise</CellsTitle>
        <Form checkbox onChange={this.handleChange('industry')}>
          <FormCell checkbox>
              <Checkbox value="industry_consumer" defaultChecked={this.props.sessionStore.User.industry_consumer}/>
              <CellBody>Consumer</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_financial" defaultChecked={this.props.sessionStore.User.industry_financial}/>
            <CellBody>Financial</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_industrial" defaultChecked={this.props.sessionStore.User.industry_industrial}/>
            <CellBody>Industrial</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_healthcare" defaultChecked={this.props.sessionStore.User.industry_healthcare}/>
            <CellBody>Healthcare</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_lifeSciences" defaultChecked={this.props.sessionStore.User.industry_lifeSciences}/>
            <CellBody>Life Sciences</CellBody>
          </FormCell>
          <FormCell checkbox>
            <Checkbox value="industry_technology" defaultChecked={this.props.sessionStore.User.industry_technology}/>
            <CellBody>Technology</CellBody>
          </FormCell>
        </Form>
        <ButtonArea>
          {this.alert()}
          <Button onClick={() => this.handleSubmit()}>Done</Button>
        </ButtonArea>
       <NonCacheTabNav />
      </div>
    );
  }
}
