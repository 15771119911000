import {observable, action} from 'mobx';
import api from '../shared/api';

class CandidateConfirmationStore {

  @observable currentCandidateConfirmation = {};
  @observable reacted = false;

  constructor() {
  }

  @action('candidate/confirmation: get confirmation') async getConfirmation(id) {
    try {
      this.loading = true;
      const json = await api.fetch(`candidate/confirmation/${id}`);
      this.currentCandidateConfirmation = json;
      console.log(json)
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('candidate/confirmation: make confirmation') async makeConfirmation(accept) {
    try {
      this.loading = true;
      this.reacted = true;
      const json = await api.update(`candidate/confirmation/${this.currentCandidateConfirmation.id}`, {accept});
      if (json.success) {
        this.loading = false;
      }
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }
}

export default new CandidateConfirmationStore();
