import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import './App.css';
import location from '../shared/location';

import {Form, FormCell} from 'react-weui';
import {Input, Label, Button, ButtonArea, Radio, TextArea, Select, Switch} from 'react-weui';
import {CellHeader, CellsTitle, CellBody, CellFooter} from 'react-weui';
import {Icon} from 'antd';

import 'weui';
import shortid from 'shortid';
import 'react-weui/build/packages/react-weui.css';
import {NonCacheTabNav} from './NonCacheTabNav';

@inject('sessionStore','routingStore', 'candidateStore', 'jobStore', 'uploadStore')
@observer
export default class Candidate_create extends Component {

  @observable currentJs = 1;
  @observable jobId;
  @observable form = {};
  @observable file = {};
  @observable uploadFile = false;

  constructor(props, context) {
    super(props, context);
    this.jobId = /[^job/]+/.exec(this.props.routingStore.location.pathname)[0];
  }

  async componentWillMount() {
    await this.props.jobStore.showJob(this.jobId);
    this.form = (await this.props.sessionStore.getNewCandidate(this.jobId)) || {
      jobId: this.jobId,
      city: 'beijing',
      countryCode: '86',
    };
    if (this.form.upload) {
      this.file = await this.props.uploadStore.getUpload({ code: this.form.upload });
    }
  }

  async handleSubmit() {
    const json = await this.props.candidateStore.createCandidate(this.form);
    if (json) {
      window.location = `/job/${this.jobId}/candidates`
    }
  }

  handleChange(field) {
    return (e) => {
      this.form[field] = e.target.value;
      this.props.sessionStore.saveNewCandidate(toJS(this.form), this.jobId);
    }
  }

  getForm(field) {
    return this.form[field];
  }

  jumptoUpload() {
    const id = shortid.generate();
    this.form['upload'] = id;
    this.props.sessionStore.saveNewCandidate(toJS(this.form), this.jobId);
    window.location = `/uploads/${id}`;
  }

  @observer
  handleSwitch(e) {
    this.uploadFile = !this.uploadFile;
  }

  @observer
  clearUploadFile() {
    if (this.file.fileName) {
      this.file = {};
      this.form.upload = null;
    }
  }

 render() {
   return (
     <div style={{backgroundColor: "#eee", paddingBottom:50}}>
     <div className="App-header">
       <h2>Recommend Candidate</h2>
       Recommending for {this.props.jobStore.currentJob.name} {this.props.jobStore.currentJob.title}
     </div>
       <CellsTitle>Candidate Profile<br/></CellsTitle>
       <Form>
         <FormCell>
             <Label>Current Title</Label>
             <Input onChange={this.handleChange('title')} placeholder="An one-liner" type="text" value={this.getForm('title')} />
         </FormCell>
         <FormCell>
           <CellBody>
             <Label style={{marginBottom: 10}}>Candidate Highlight(s)</Label>
             <TextArea placeholder="Briefly summarize the candidate’s information, include but not limited to Competence, Skill, Seniority, Accomplishments, etc." rows="7" showCounter={false} onChange={this.handleChange('profile')} value={this.getForm('profile')}></TextArea>
           </CellBody>
         </FormCell>
         <FormCell>
           <Label>Current Salary</Label>
           <Input onChange={this.handleChange('last_salary')} type="text" value={this.getForm('last_salary')}/>
         </FormCell>
         <FormCell>
           <Label>Expected Salary</Label>
           <Input onChange={this.handleChange('expected_salary')} type="text" value={this.getForm('expected_salary')}/>
         </FormCell>
       </Form>
       <CellsTitle>Terms<br/>You can propose additional terms to poster's offer</CellsTitle>
       <Form>
         <FormCell>
           <CellBody>
             <TextArea rows="7" showCounter={false} onChange={this.handleChange('terms')} value={this.getForm('terms')}></TextArea>
           </CellBody>
         </FormCell>
       </Form>
       <br/>
<hr/>
       <CellsTitle>Candidate CV<br/>CONTENTS WILL BE SHOWN TO POSTER UPON ACCEPTANCE OF TERMS AND CONDITIONS</CellsTitle>
       <Form>
           <FormCell>
             <Label>First Name {this.file.fileName && '(Required)'}</Label>
             <Input onChange={this.handleChange('first_name')} type="text" value={this.getForm('first_name')}/>
           </FormCell>
           <FormCell>
             <Label>Last Name {this.file.fileName && '(Required)'}</Label>
             <Input onChange={this.handleChange('last_name')} type="text" value={this.getForm('last_name')} />
           </FormCell>
       </Form>
         <Form radio onChange={this.handleChange('countryCode')} value={this.getForm('countryCode')}>
         <CellsTitle>Country code {this.file.fileName && '(Required)'}</CellsTitle>
           <FormCell radio>
             <CellBody>Mainland China (+86)</CellBody>
             <CellFooter>
               <Radio name="radio2" value="86" defaultChecked={this.getForm('countryCode') === '86'} />
             </CellFooter>
           </FormCell>
           <FormCell radio>
             <CellBody>Hong Kong (+852)</CellBody>
             <CellFooter>
               <Radio name="radio2" value="852" defaultChecked={this.getForm('countryCode') === '852'} />
             </CellFooter>
           </FormCell>
         </Form>
         <Form>
           <FormCell>
             <Label>Mobile No. {this.file.fileName && '(Required)'}</Label>
             <Input onChange={this.handleChange('tel')} type="text" value={this.getForm('tel')}/>
           </FormCell>
           <FormCell>
             <Label>WeChat {this.file.fileName && '(Optional)'}</Label>
             <Input onChange={this.handleChange('wechat')} type="text" value={this.getForm('wechat')}/>
           </FormCell>
         </Form>
         <CellsTitle>CV</CellsTitle>
         { !this.file.fileName &&
         <Form>
           <FormCell switch>
             <CellBody>
               Upload CV
               <div style={{textAlign: 'center', marginTop: '10px'}}><Switch onClick={(e) => this.handleSwitch(e)}/></div>
             </CellBody>
           </FormCell>
         </Form>
        }
        {(this.uploadFile || this.file.fileName) &&
       <Form>
       {!this.file.fileName &&
         <FormCell>
           <CellHeader><Label>Upload CV file</Label></CellHeader>
           <CellBody>
             <Button onClick={() => this.jumptoUpload()}>Click here to upload</Button>
           </CellBody>
         </FormCell>
       }
       {this.file.fileName &&
         <FormCell>
           <CellHeader><Label>CV file</Label></CellHeader>
           <div style={{display: 'flex'}}>
            <CellBody>
              <div style={{textAlign: 'center', margin: 10}}><Icon type="file" theme="twoTone" /><br />{this.file.fileName}</div>
              <Button type="warn" onClick={() => this.clearUploadFile()}>remove file</Button> 
            </CellBody>
           </div>
         </FormCell>
       }
   </Form>
        }
        {!this.uploadFile && !this.file.fileName &&
         <Form>
           <FormCell select selectPos={'after'}>
             <CellHeader>
               <Label>Province/City {this.file.fileName && '(Optional)'}</Label>
             </CellHeader>
             <CellBody>
               <Select data={location.getLocationForWxForm()} onChange={this.handleChange('city')} value={this.getForm('city')} />
             </CellBody>
           </FormCell>
           <FormCell>
             <Label>Location {this.file.fileName && '(Optional)'}</Label>
             <Input onChange={this.handleChange('address')} type="text" value={this.getForm('address')} />
           </FormCell>
           <CellsTitle>Working Experience {this.file.fileName && '(Optional)'}</CellsTitle>
           <FormCell>
             <CellBody>
               <TextArea placeholder="Past duties and responsibilities" rows="7" showCounter={false} onChange={this.handleChange('cv_workingExp')} value={this.getForm('cv_workingExp')}></TextArea>
             </CellBody>
           </FormCell>
           <CellsTitle>Qualifications {this.file.fileName && '(Optional)'}</CellsTitle>
           <FormCell>
             <CellBody>
               <TextArea rows="7" showCounter={false} onChange={this.handleChange('cv_qualification')} value={this.getForm('cv_qualification')}></TextArea>
             </CellBody>
           </FormCell>
         </Form>
        }
       <ButtonArea>
         <Button onClick={() => this.handleSubmit()}>Submit</Button>
       </ButtonArea>
       <NonCacheTabNav />
   </div>)
 }
}