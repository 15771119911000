import {observable, action} from 'mobx';
import api from '../shared/api';

export class UploadStore {

  @observable loading = false;

  @action('shoot upload result to db') async shootUpload({ document, code, fileName }) {
    this.loading = true;
    try {
     const json = await api.create('uploads/fin', {
       document,
       code,
       fileName,
     });
     this.loading = false;
     return Promise.resolve(json);
    } catch (e) {
      this.loading = false;
      return Promise.resolve(false);
    }
  }

  @action('check if the upload is already here') async getUpload({ code }) {
    this.loading = true;
    try {
     const json = await api.fetch(`upload/${code}`);
     this.loading = false;
     return Promise.resolve(json);
    } catch (e) {
      this.loading = false;
      return Promise.resolve(false);
    }
  }
}

export default new UploadStore();