import {observable, action} from 'mobx';
import api from '../shared/api';

class JobStore {

  @observable specialties = [];
  @observable functions = [];
  @observable currentNews = {};
  @observable news = { data : [] };
  @observable loading = false;

  constructor() {
  }

  @action('news: new News') async createJob(job) {
    try {
      const json = await api.create(`/news`, job)
      return Promise.resolve(json)
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @action('news: show all News') async listJob(query = {}) {
    this.loading = true;
    try {
      query.$limit = 30;
      const json = await api.fetch(`news`, query);
      this.news = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('news: show Job') async showJob(id) {
    this.loading = true;
    try {
      const json = await api.fetch(`news/${id}`);
      this.currentJob = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }
}

export default new JobStore();
