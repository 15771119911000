import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import './App.css'

import {Form, FormCell} from 'react-weui';
import {Label, Button} from 'react-weui';
import {CellHeader, CellsTitle, CellBody} from 'react-weui';

import 'weui';
import 'react-weui/build/packages/react-weui.css';

import salaryRange from '../shared/salaryRange';
import location from '../shared/location';

@inject('routingStore', 'candidateConfirmationStore')
@observer
export default class Job_get_public extends Component {

  @observable tab;
  @observable jobId;
  @observable isesUser = false;

  constructor(props, context) {
    super(props, context);
  }

  async componentWillMount() {
    this.id = this.props.id;
    await this.props.candidateConfirmationStore.getConfirmation(this.id);
    document.title = '';
  }

  async accept(accept) {
    await this.props.candidateConfirmationStore.makeConfirmation(accept);
  }

  render() {
    return (
      <div style={{backgroundColor: "#eee", paddingBottom:50}}>
        <div style={{display: this.isesUser? 'block': 'none', textAlign: "center", backgroundColor: "#f1f1f1", color:"#000", padding:2, fontSize:9}}>You are safe to share this directly to your candidate via any apps</div>
        <div className="App-header">
          <h3>Hi {this.props.candidateConfirmationStore.currentCandidateConfirmation.candidateFirstName}&nbsp;{this.props.candidateConfirmationStore.currentCandidateConfirmation.candidateLastName}, please review and confirm the recommendation of your opportunity at the bottom:</h3>
          <h3>{ this.props.candidateConfirmationStore.currentCandidateConfirmation.name }</h3>
        </div>
        <CellsTitle>Your recommending agent</CellsTitle>
        <Form>
          <FormCell>
            <CellHeader>
              <Label>Name</Label>
            </CellHeader>
            <CellBody>
              { this.props.candidateConfirmationStore.currentCandidateConfirmation.providerFirstName }
              &nbsp;
              { this.props.candidateConfirmationStore.currentCandidateConfirmation.providerLastName }
            </CellBody>
          </FormCell>
          <FormCell>
            <CellHeader>
              <Label>Mobile</Label>
            </CellHeader>
            <CellBody>
              {this.props.candidateConfirmationStore.currentCandidateConfirmation.providerMobile}
            </CellBody>
          </FormCell>
          <FormCell>
            <CellHeader>
              <Label>Email</Label>
            </CellHeader>
            <CellBody>
              {this.props.candidateConfirmationStore.currentCandidateConfirmation.providerEmail}
            </CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Job Location</CellsTitle>
        <Form>
          <FormCell>
            <CellHeader>
              <Label>Province/City</Label>
            </CellHeader>
            <CellBody>
              { location.getLocationByAbb(this.props.candidateConfirmationStore.currentCandidateConfirmation.city) }
            </CellBody>
          </FormCell>
          <FormCell>
            <Label>Detailed Job Location</Label>
            { this.props.candidateConfirmationStore.currentCandidateConfirmation.address }
          </FormCell>
        </Form>
        <CellsTitle>Highlight(s)</CellsTitle>
        <Form>
          <FormCell>
            <CellBody style={{whiteSpace: "pre-line"}}>
              { this.props.candidateConfirmationStore.currentCandidateConfirmation.highlight }
            </CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Job Description</CellsTitle>
        <Form>
          <FormCell>
            <CellBody style={{whiteSpace: "pre-line"}}>
              { this.props.candidateConfirmationStore.currentCandidateConfirmation.jobDescription }
            </CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Qualifications</CellsTitle>
        <Form>
          <FormCell>
            <CellBody style={{whiteSpace: "pre-line"}}>
              { this.props.candidateConfirmationStore.currentCandidateConfirmation.qualifications }
            </CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Salary package in HKD</CellsTitle>
        <Form>
          <FormCell>
            <CellBody>
              {salaryRange.getSalaryRangeAsString(toJS(this.props.candidateConfirmationStore.currentCandidateConfirmation.salaryRange))}
            </CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Declarations</CellsTitle>
        { !this.props.candidateConfirmationStore.reacted &&
          (!this.props.candidateConfirmationStore.currentCandidateConfirmation.agreed_candidate &&
          !this.props.candidateConfirmationStore.currentCandidateConfirmation.declined_candidate) &&
        <Form>
          <FormCell>
            <CellBody>I agree {this.props.candidateConfirmationStore.currentCandidateConfirmation.providerFirstName}'s
              recommendation.<br/>
              I accept this job opportunity.<br/>
              I am willing to disclose my personal information to the poster to continue the next step.
              <Button onClick={() => this.accept(true)}>I confirm all of the above.</Button>
              <Button type={'warn'} onClick={() => this.accept(false)}>I reject all/any of the above.</Button>
            </CellBody>
          </FormCell>
        </Form>
        }
        {
          (this.props.candidateConfirmationStore.reacted || this.props.candidateConfirmationStore.currentCandidateConfirmation.agreed_candidate ||
          this.props.candidateConfirmationStore.currentCandidateConfirmation.declined_candidate) &&
          <div style={{textAlign: "center"}}>You had reacted to this confirmation, thank you.</div>
        }
      </div>)
  }
}
