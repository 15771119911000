import {observable, action} from 'mobx';
import api from '../shared/api';

class WxStore {

  @observable config;
  @observable expiresTime;
  @observable loading = false;

  constructor() {
    this.timestamp = 0;
  }

  @action('wx: getConfig') async getConfig(url){
    this.loading = true;
    try {
      const json = await api.fetch(`wxjssdk?url=${process.env.PUBLIC_URL}${url}`);
      this.expiresTime = json.expiresTime;
      delete json.expiresTime;
      this.config = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }
}

export default new WxStore();
