import { CookiesProvider, withCookies, Cookies } from 'react-cookie';

export default class ClientStorage {

  constructor() {
    let detectSessionStorage = null;
    try {
      window.sessionStorage && window.sessionStorage.setItem('test', '');
      detectSessionStorage = false;
    } catch (e) {
      detectSessionStorage = false;
    }

    this.adapter = detectSessionStorage ?
      this._sessionStorageAdapter() : this._localObjectAdapter();
  }

  set(key, value) {
    this.adapter.set(key, value);
  }

  del(key) {
    this.adapter.del(key);
  }

  get(key) {
    return this.adapter.get(key);
  }

  _localObjectAdapter() {
    const cookies = new Cookies();
    return {
      set: (key, value) => {
        cookies.set(key, value, {maxAge: 3600});
      },
      get: (key) => {
        return cookies.get(key);
      },
      del: (key) => {
        cookies.remove(key);
      },
    }
  }

  _sessionStorageAdapter() {
    return {
      del(key) {
        window.sessionStorage.removeItem(key);
      },
      set(key, value) {
        if (value) window.sessionStorage.setItem(key, JSON.stringify(value));
      },
      get(key) {
        const value = window.sessionStorage.getItem(key);

        if (value && value.length) {
          return JSON.parse(window.sessionStorage.getItem(key));
        }

        return null;
      }
    };
  }
}
