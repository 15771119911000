import React, { Component } from 'react';
import { inject } from 'mobx-react';
import {Tab, TabBarItem} from 'react-weui'

@inject('routingStore')
export class NonCacheTabNav extends Component {
  render() {
    return (
    <Tab type="tabbar" className="nonCacheTabNav" defaultIndex={-1}>
    <TabBarItem icon={
      <a href="#" onClick={() => this.props.routingStore.push(`/`)}><svg fill="currentColor" viewBox="0 0 40 40">
        <g><path d="m15.9 23.4c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5-7.5 3.3-7.5 7.5 3.3 7.5 7.5 7.5z m10 0l8.2 8.2-2.5 2.5-8.2-8.2v-1.4l-0.5-0.4c-1.9 1.6-4.4 2.5-7 2.5-6.1 0-10.9-4.7-10.9-10.7s4.8-10.9 10.9-10.9 10.7 4.8 10.7 10.9c0 2.6-0.9 5.1-2.5 7l0.4 0.5h1.4z" /></g>
      </svg></a>
    } label="Jobs">
    </TabBarItem>
    <TabBarItem icon={
      <a href="#" onClick={() => this.props.routingStore.push(`/?tab=1`)}><svg fill="currentColor" viewBox="0 0 40 40">
        <g><path d="m31.6 25v-16.6h-23.3v16.6h6.7c0 2.7 2.3 5 5 5s5-2.3 5-5h6.6z m0-20c1.8 0 3.4 1.5 3.4 3.4v23.2c0 1.8-1.6 3.4-3.4 3.4h-23.3c-1.9 0-3.3-1.6-3.3-3.4v-23.2c0-1.9 1.4-3.4 3.3-3.4h23.3z" /></g>
      </svg></a>
    } label="My Referrals">
    </TabBarItem>
    <TabBarItem icon={
      <a href="#" onClick={() => this.props.routingStore.push(`/?tab=2`)}><svg fill="currentColor" viewBox="0 0 40 40">
        <g><path d="m20 36.6c-1.9 0-3.4-1.4-3.4-3.2h6.7c0 1.9-1.5 3.2-3.3 3.2z m10-18.2v8.2l3.4 3.4v1.6h-26.8v-1.6l3.4-3.4v-8.2c0-5.2 2.7-9.4 7.5-10.6v-1.2c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v1.2c4.8 1.2 7.5 5.5 7.5 10.6z m3.3-0.9c-0.3-4.5-2.5-8.3-5.9-10.7l2.4-2.3c3.9 3 6.6 7.7 6.8 13h-3.3z m-20.6-10.7c-3.5 2.4-5.7 6.2-6 10.7h-3.3c0.2-5.3 2.9-10 6.8-13z" /></g>
      </svg></a>
    } label="Activities">
    </TabBarItem>
    <TabBarItem icon={
    <a href="#" onClick={() => this.props.routingStore.push(`/?tab=3`)}><svg fill="currentColor" viewBox="0 0 40 40">
      <g><path d="m20 21.6q2.5 0 5.5 0.7t5.4 2.4 2.5 3.7v5h-26.8v-5q0-2.1 2.5-3.7t5.4-2.4 5.5-0.7z m0-15q2.7 0 4.7 2t1.9 4.8-1.9 4.6-4.7 2-4.7-2-1.9-4.6 1.9-4.8 4.7-2z m0 18.2q-3.4 0-6.8 1.3t-3.4 2.3v1.8h20.4v-1.8q0-1-3.4-2.3t-6.8-1.3z m0-15q-1.5 0-2.5 1.1t-1 2.5 1 2.4 2.5 1 2.5-1 1-2.4-1-2.5-2.5-1.1z" /></g>
    </svg></a>
    }label="Profile">
    </TabBarItem>
    </Tab>)
  }
}