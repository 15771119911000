import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {toJS } from 'mobx';
import './App.css'

import {Cell} from 'react-weui';
import {Cells, CellBody, CellFooter, Toast} from 'react-weui';
import {Flex, FlexItem} from 'react-weui'

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import moment from 'moment';

@inject('routingStore')
class NewsCells extends Component {
  render() {
    if (this.props.loading) {
      return <div style={{height: 120}}>
          <Toast icon="loading" show={true}>Loading...</Toast>
        </div>
    }
    if (this.props.data.length) {
      return <Cells>{this.props.data.map(v =>
        <Cell onClick={() => this.props.routingStore.push(v.url)} access>
          <CellBody>
          <Flex>
            <FlexItem style={{fontSize: 10}}>
              News
            </FlexItem>
            <FlexItem style={{fontSize:10, textAlign: 'right'}}>
              {moment(v.created_at).utcOffset(8).format('D/M/YY, h:mm a')}
            </FlexItem>
          </Flex>
            <b>{v.message}</b>
          </CellBody>
          <CellFooter>
          </CellFooter>
        </Cell>
      )}</Cells>
    } else {
      return <div style={{textAlign:"center", color:"#959595", padding: 20}}><br />No news yet</div>;
    }
  }
}

@inject('routingStore','sessionStore','newsStore','candidateStore')
@observer
export default class News extends Component {

  constructor(props, context) {
    super(props, context);
  }

  async componentWillMount() {
    await this.props.newsStore.listJob();
  }

  render() {
    return (
      <div style={{backgroundColor: "#eee", paddingBottom: 10}}>
        <div className="App-header">
          <h2>Activites</h2>
          Status changes and news
        </div>
        <NewsCells data={toJS(this.props.newsStore.news).data} loading={this.props.newsStore.loading}/>
      </div>
    );
  }
}
