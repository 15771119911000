class SalaryRange {
  range = [
    {label: "< $   500,000 HKD"},
    {label: "~ $   800,000 HKD"},
    {label: "~ $ 1,000,000 HKD"},
    {label: "> $ 1,500,000 HKD"}
  ];

  getSalaryRangeForWx(index) {
    if (!index) index = 0;
    return this.range[index];
  }

  getSalaryRangeAsString(index) {
    if (!index) index = 0;
    return this.range[index].label;
  }
}

export default new SalaryRange();