import {observable, action} from 'mobx';
import api from '../shared/api';

class JobStore {

  @observable loading = false;
  @observable specialties = [];
  @observable functions = [];
  @observable currentJob = {};
  @observable jobs = {data: []};

  constructor() {
  }

  @action('job: get specialties')
  async getSpecialties() {
    this.loading = true;
    try {
      const json = await api.fetch(`jobs/specialties`);
      this.specialties = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('job: get functions')
  async getFunctions() {
    this.loading = true;
    try {
      const json = await api.fetch(`jobs/functions`);
      this.functions = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('job: new Job')
  async createJob(job) {
    try {
      const json = await api.create(`jobs`, job)
      return Promise.resolve(json)
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @action('job: show all Job')
  async listJob(query = {}) {
    this.loading = true;
    try {
      query.$limit = 999;
      query.$sort = {
        created_at: -1
      };
      const json = await api.fetch(`jobs`, query);
      console.log(json)
      this.jobs = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('job opportunity: show public Job')
  async showPublicJob(id) {
    try {
      const json = await api.fetch(`jobs/${id}`, {'public': 'y'});
      this.currentJob = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('job: show Job')
  async showJob(id) {
    try {
      const json = await api.fetch(`jobs/${id}`);
      this.currentJob = json;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('job: request edit orginal job')
  async shouldEditOrginalJob(id) {
    try {
      const json = await api.fetch(`jobs/${id}?edit=true`);
      if (json.editOriginal) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } catch (err) {
      this.loading = false;
      return Promise.reject(err);
    }
  }

  @action('job: edit Job')
  async editJob(id, job) {
    try {
      delete job.id;
      delete job.created_at;
      delete job.updated_at;
      delete job.archived;
      delete job.isMine;
      delete job.ownerCompany;
      job.fromEditJobId = id;
      var json = await this.createJob(job);
      return Promise.resolve(json);
    } catch (err) {

    }
  }
}

export default new JobStore();
