import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS} from 'mobx';
import './App.css'

import {Form, FormCell} from 'react-weui';
import {Input, Label, Button, ButtonArea, Radio, TextArea, Select, Switch} from 'react-weui';
import {CellHeader, CellsTitle, CellBody, CellFooter} from 'react-weui';
import {Icon} from 'antd'

import 'weui';
import shortid from 'shortid';
import 'react-weui/build/packages/react-weui.css';
import locations from '../shared/location';

@inject('sessionStore','jobStore', 'routingStore', 'uploadStore')
@observer
export default class Job_edit extends Component {

  @observable currentJs = 1;
  @observable file = {};
  @observable form;
  @observable edit = false;
  @observable pickup = false;
  @observable uploadFile = false;

  constructor(props, context) {
    super(props, context);
    this.form = {};
  }

  async componentWillMount() {
    if (this.props.routingStore.location.pathname.match('edit')) {
      this.edit = true;
      this.jobId = /[^\/]+(?=\/edit)/.exec(this.props.routingStore.location.pathname)[0];
      await this.props.jobStore.showJob(this.jobId);
      await this.props.jobStore.getSpecialties();
      await this.props.jobStore.getFunctions();
      this.form = toJS(this.props.jobStore.currentJob);
    } else {
      this.edit = false;
      await this.props.jobStore.getSpecialties();
      await this.props.jobStore.getFunctions();
      this.form = (await this.props.sessionStore.getNewJob()) || {};
    }
    if (this.form.specialties) {
      this.currentJs = toJS(this.form.specialties);
    }
    if (this.form.upload) {
      this.file = await this.props.uploadStore.getUpload({ code: this.form.upload });
    }
  }

  jobSpecialties() {
    try {
      if (this.props.jobStore.specialties && this.props.jobStore.currentJob) {
        const id = toJS(this.props.jobStore.currentJob.specialties)
        const special = toJS(this.props.jobStore.specialties[id - 1]);
        return special.name
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {
      console.log(err);
    }
  }

  jobSubSpecialties() {
    try {
      if (this.props.jobStore.specialties && this.props.jobStore.currentJob) {
        const jsid = toJS(this.props.jobStore.currentJob.specialties);
        const id = toJS(this.props.jobStore.currentJob.subSpecialties);
        const special = toJS(this.props.jobStore.specialties[jsid - 1]);
        return special.subSpecialties[id - special.subSpecialties[0].id].name
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {
      console.log(err);
    }
  }

  jobFunctions() {
    try {
      if (this.props.jobStore.functions) {
        const id = toJS(this.props.jobStore.currentJob.function);
        const functions = toJS(this.props.jobStore.functions[id - 1]);
        return functions.name;
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {

    }
  }

  async handleSubmit() {
    delete this.form.ownerFirstName
    delete this.form.ownerLastName
    delete this.form.ownerWeChat
    delete this.form.isMine
    delete this.form.ownerCompany
    delete this.form.created_at
    delete this.form.updated_at
    delete this.form.id
    if (this.edit) {
      const json = await this.props.jobStore.editJob(this.jobId, this.form);
      if (json) {
        window.location = `/job/${this.jobId}`
      }
    } else {
      const json = await this.props.jobStore.createJob(this.form);
      if (json) {
        window.location = `/job/${json.id}`
      }
    }
  }

  handleChange(field) {
    if (field === "specialties") {
      return (e) => {
        this.currentJs = e.target.value;
        this.form.subSpecialties = this.props.jobStore.specialties[this.currentJs - 1].subSpecialties[0].id;
        if (this.form[field] !== parseInt(e.target.value))
            this.form[field] = parseInt(e.target.value);
        this.props.sessionStore.saveNewJob(toJS(this.form));
      }
    } else if (field === "subSpecialties" || field === "function" || field === "salaryRange") {
      return (e) => {
        if (this.form[field] !== parseInt(e.target.value))
            this.form[field] = parseInt(e.target.value);
        this.props.sessionStore.saveNewJob(toJS(this.form));
      }
    } else {
      return (e) => {
        if (this.form[field] !== e.target.value)
            this.form[field] = e.target.value;
        this.props.sessionStore.saveNewJob(toJS(this.form));
      }
    }
  }

  getForm(field) {
    return this.form[field];
  }

  jobSpecialties() {
    if (this.props.jobStore.specialties) {
      return this.props.jobStore.specialties.map(function (v) {
          return {
            value: v.id,
            label: v.name
          }
        }
      )
    } else {
      return [{
        value: 1,
        label: 'loading...'
      }]
    }
  }

  @observer
  jobSubSpecialties() {
    if (this.props.jobStore.specialties[0]) {
      return this.props.jobStore.specialties[this.currentJs - 1].subSpecialties.map(function (v) {
          return {
            value: v.id,
            label: v.name
          }
        }
      );
    }
  }

  jobFunctions() {
    if (this.props.jobStore.functions) {
      return this.props.jobStore.functions.map(function (v) {
          return {
            value: v.id,
            label: v.name
          }
        }
      )
    } else {
      return [{
        value: 1,
        label: 'loading...'
      }]
    }
  }

  jumptoUpload() {
    const id = shortid.generate();
    this.form['upload'] = id;
    this.props.sessionStore.saveNewJob(toJS(this.form));
    window.location = `/uploads/${id}`;
  }

  @observer
  handleSwitch(e) {
    this.uploadFile = !this.uploadFile;
  }

  @observer
  clearUploadFile() {
    if (this.file.fileName) {
      this.file = {};
      this.form.upload = null;
    }
  }

 render() {
   return (
     <div style={{backgroundColor: "#eee", paddingBottom:50}}>
     <div className="App-header">
       <h2>{this.edit? 'Edit Job': 'Create Job'}</h2>
       {this.edit? `Edit job ${this.props.jobStore.currentJob.name}.`: 'Create a new job and share it right away to our network.'}
     </div>
       { (!this.edit || (this.edit && this.form.specialties)) &&
       <div>
       <Form>
         <FormCell>
           <Label>Job Name/Title</Label>
           <Input onChange={this.handleChange('name')} type="text" value={this.getForm('name')}/>
         </FormCell>
       </Form>
       <CellHeader>
         <Label>&nbsp;</Label>
       </CellHeader>
       <Form>
         <FormCell  select selectPos="after">
           <CellHeader>
             <Label>Industry</Label>
           </CellHeader>
           <CellBody>
             <Select data={this.jobSpecialties()} onChange={this.handleChange('specialties')} value={this.getForm('specialties')} />
           </CellBody>
         </FormCell>
         <FormCell  select selectPos="after">
           <CellHeader>
             <Label>Specialties</Label>
           </CellHeader>
           <CellBody>
             <Select data={this.jobSubSpecialties()} onChange={this.handleChange('subSpecialties')} value={this.getForm('subSpecialties')}/>
           </CellBody>
         </FormCell>
         <FormCell  select selectPos="after">
           <CellHeader>
             <Label>Function</Label>
           </CellHeader>
           <CellBody>
             <Select data={this.jobFunctions()} onChange={this.handleChange('function')} value={this.getForm('function')}/>
           </CellBody>
         </FormCell>
       </Form>
       <CellsTitle>Job Location</CellsTitle>
       <Form>
         <FormCell  select selectPos="after">
           <CellHeader>
             <Label>Province/City</Label>
           </CellHeader>
           <CellBody>
             <Select data={locations.getLocationForWxForm()} onChange={this.handleChange('city')} value={this.getForm('city')}/>
           </CellBody>
         </FormCell>
         <FormCell>
           <Label>Detailed Job Location</Label>
           <Input onChange={this.handleChange('address')} type="text" value={this.getForm('address')} />
         </FormCell>
       </Form>
       <CellsTitle>Highlight(s)</CellsTitle>
       <Form>
         <FormCell>
           <CellBody>
             <TextArea placeholder="Briefly summarize the job relevant information, include but not limited to company background, primary purpose, major challenges & complexities, specific skills and abilities, benefits to candidate etc." rows="7" showCounter={false} onChange={this.handleChange('highlight')} value={this.getForm('highlight')}></TextArea>
           </CellBody>
         </FormCell>
       </Form>
       <CellsTitle>Job Details</CellsTitle>
       <Form>
         <CellsTitle>Job Description</CellsTitle>
         <FormCell>
           <CellBody>
             <TextArea placeholder="Duties and responsibilities" rows="7" showCounter={false} onChange={this.handleChange('jobDescription')} value={this.getForm('jobDescription')}></TextArea>
           </CellBody>
         </FormCell>
         <CellsTitle>Qualifications</CellsTitle>
         <FormCell>
           <CellBody>
             <TextArea rows="7" showCounter={false} onChange={this.handleChange('qualifications')} value={this.getForm('qualifications')}></TextArea>
           </CellBody>
         </FormCell>
       </Form>
       <CellsTitle>Salary package in HKD</CellsTitle>
       <Form radio onChange={this.handleChange('salaryRange')}>
         <FormCell radio>
           <CellBody>&lt; $&nbsp;&nbsp;&nbsp;500,000 HKD</CellBody>
           <CellFooter>
             <Radio name="radio1" value="0" defaultChecked={this.getForm('salaryRange') === 0}/>
           </CellFooter>
         </FormCell>
         <FormCell radio>
           <CellBody>~ $&nbsp;&nbsp;&nbsp;800,000 HKD</CellBody>
           <CellFooter>
             <Radio name="radio1" value="1" defaultChecked={this.getForm('salaryRange') === 1}/>
           </CellFooter>
         </FormCell>
         <FormCell radio>
           <CellBody>~ $1,000,000 HKD</CellBody>
           <CellFooter>
             <Radio name="radio1" value="2" defaultChecked={this.getForm('salaryRange') === 2}/>
           </CellFooter>
         </FormCell>
         <FormCell radio>
           <CellBody>> $1,500,000 HKD</CellBody>
           <CellFooter>
             <Radio name="radio1" value="3" defaultChecked={this.getForm('salaryRange') === 3}/>
           </CellFooter>
         </FormCell>
       </Form>
       <CellsTitle>Terms with partners</CellsTitle>
       <Form>
         <FormCell>
           <Label>Client Fee (Optional)</Label>
           <Input onChange={this.handleChange('clientFee')} type="text" value={this.getForm('clientFee')}/>
         </FormCell>
         <FormCell>
           <Label>Rewards</Label>
           <Input onChange={this.handleChange('split')} type="text" value={this.getForm('split')}/>
         </FormCell>
         <FormCell>
           <Label>Terms</Label>
           <CellBody>
             <TextArea onChange={this.handleChange('terms')} placeholder="Terms: i.e. Guarantee Period & budget etc" rows="7" showCounter={false} value={this.getForm('terms')}></TextArea>
           </CellBody>
         </FormCell>
       </Form>
       <ButtonArea>
         <Button onClick={() => this.handleSubmit()}>Submit</Button>
       </ButtonArea>
       </div> }
   </div>)
 }
}
