import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import './App.css'

import {Cell} from 'react-weui';
import {Cells, CellsTitle, CellBody, CellFooter} from 'react-weui';
import {Tab, NavBar, NavBarItem} from 'react-weui'

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import moment from 'moment';
import {NonCacheTabNav} from './NonCacheTabNav';

@inject('routingStore')
class CandidateCells extends Component {
  render() {
    if (this.props.data.length) {
      return <Cells>{this.props.data.map(v =>
        <Cell onClick={() => this.props.routingStore.push(`candidate/${v.id}`)} access>
          <CellBody>
            <b>{v.title}</b><br /><span style={{fontSize:10}}>{v.created_at}</span>
          </CellBody>
          { this.props.owner &&
            <CellFooter>
            { (!v.agreed_jobOwner && !v.agreed_candidate && "Awaiting your agreement") }
            { (v.agreed_jobOwner && v.agreed_candidate && "Both party agreed") }
            { (v.agreed_jobOwner && !v.agreed_candidate && "Awaiting candidate agreement") }
            { (v.declined_jobOwner && "Rejected") }
            { (v.declined_candidate && "Candidate is declined") }
            <br />
            <span style={{fontSize: 10}}>Provider: {v.providerFirstName} {v.providerLastName}</span>
            </CellFooter>
          }
          { !this.props.owner &&
          <CellFooter>
            { (!v.agreed_candidate && !v.agreed_jobOwner && "Awaiting agreement") }
            { (v.agreed_jobOwner && v.agreed_candidate && "Both party agreed") }
            { (v.agreed_jobOwner && !v.agreed_candidate && "Awaiting candidate agreement") }
            { (v.declined_jobOwner && "Rejected") }
            { (v.declined_candidate && "Candidate is declined") }
            <br />
          </CellFooter>
          }
        </Cell>
      )}</Cells>
    } else {
      return <div style={{textAlign:"center", color:"#959595", padding: 20}}>No candidate yet</div>;

    }
  }
}

@inject('routingStore','sessionStore','jobStore','candidateStore')
@observer
export default class Candidates extends Component {

  @observable jobId;

  constructor(props, context) {
    super(props, context);
  }

  getCandidates() {
    try {
      var candidates = toJS(this.props.candidateStore.candidates);
      var r = candidates.data.map((j, i) => {
        return {
          id: j.id,
          title: j.title,
          agreed_jobOwner: j.agreed_jobOwner,
          agreed_candidate: j.agreed_candidate,
          declined_jobOwner: j.declined_jobOwner,
          created_at: moment(j.created_at).utcOffset(8).format('D/M/YY, h:mm:ss a'),
          providerFirstName: j.providerFirstName,
          providerLastName: j.providerLastName
        }
      })
      return r || [];
    } catch (err) {

    }
  }

  async componentWillMount() {
    var params = {};
    this.jobId = /[^job/]+/.exec(this.props.routingStore.location.pathname)[0];
    await this.props.jobStore.showJob(this.jobId);
    if (!!this.props.jobStore.currentJob.isMine) {
      params.jobOwner = true;
    }
    await this.props.candidateStore.listCandidateOfJob(this.jobId, params);
  }

  render() {
    return (
      <div style={{backgroundColor: "#eee", paddingBottom: 50}}>
        <div className="App-header">
          <h2>Candidates</h2>
          Candidate for {this.props.jobStore.currentJob.name} {this.props.jobStore.currentJob.title}
        </div>
        <Tab>
        <NavBar>
          <NavBarItem onClick={e=>{ this.props.routingStore.goBack();}}>Return to job</NavBarItem>
          <NavBarItem style={{display: this.props.jobStore.currentJob.isMine? 'none': '', backgroundColor:"#1AAD19", color:"#FFF"}} onClick={e=>{ this.props.routingStore.push(`/job/${this.jobId}/candidate/new`);}}>Recommend Candidate</NavBarItem>
        </NavBar>
        <br /><br />
        <CellsTitle style={{display: this.props.jobStore.currentJob.isMine? '': 'none'}}>
          Candidates that others recommending to you
        </CellsTitle>
        <CellsTitle style={{display: this.props.jobStore.currentJob.isMine? 'none': ''}}>
          Your proposed candidates to this job
        </CellsTitle>
        <CandidateCells owner={this.props.jobStore.currentJob.isMine} data={this.getCandidates()} />
        </Tab>
        <NonCacheTabNav />
      </div>
    );
  }
}
