import { RouterStore } from 'mobx-react-router';
import wxStore from './wxStore';
import sessionStore from './sessionStore';
import jobStore from './jobStore';
import candidateStore from './candidateStore';
import newsStore from './newsStore';
import candidateConfirmationStore from './candidateConfirmationStore';
import invitationStore from './invitationStore';
import uploadStore from './uploadStore';

const routingStore = new RouterStore();

const stores = {
    // Key can be whatever you want
    sessionStore,
    routingStore,
    jobStore,
    candidateStore,
    candidateConfirmationStore,
    wxStore,
    newsStore,
    invitationStore,
    uploadStore,
};

export default stores
