import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable} from 'mobx';
import './App.css'

import ImageUplaod from './ImageUpload';
import { Form, FormCell, Label, Input, Button, Toptips } from 'react-weui';
import axios from 'axios';
import 'react-weui/build/packages/react-weui.css';

@inject('uploadStore')
@observer
export default class Uploads extends React.Component {

  @observable showPopup = true;
  @observable checkedButFailed = false;

  constructor(props, context) {
    super(props, context);
    this.state = {
      extBrowser: false,
      form: {},
      uploadFin: false,
      fileName: '',
    };
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeForm = this.onChangeForm.bind(this);
    this.shootChange = this.shootChange.bind(this);
  }

  async componentDidMount(props) {
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      this.setState({ extBrowser: true });
    }
  }

  onChangeName(e) {
    this.setState({ fileName: e.target.value });
  }

  onChangeForm(e, name) {
    let form = this.state.form;
    form[name] = e.target.value;
    this.setState({ form });
  }

  showPopupAgain() {
    this.showPopup = true;
  }

  cleanPopup() {
    this.showPopup = false;
    this.checkUploadStatus();
  }

  async checkUploadStatus() {
    const file = await this.props.uploadStore.getUpload({ code: this.props.code });
    if (file.fileName) {
      window.history.back();
    }
    this.checkedButFailed = true;
  }

  async shootChange() {
    const success = this.props.uploadStore.shootUpload({
      document: this.state.form.document,
      code: this.props.code,
      fileName: this.state.fileName,
    })
    if (success) {
      this.setState({ uploadFin: true });
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "#eee", paddingBottom: 50 }}>
        <div className="App-header">
          <h2>Upload file</h2>
        </div>
        {(!this.edit || (this.edit && this.form.specialties)) &&
          <div>
            {this.state.extBrowser && !this.state.uploadFin && <div>
              <div style={
                  {
                    fontSize: 20,
                    position: "absolute",
                    right: 0,
                    top: 0,
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: '#FFF',
                    textAlign: 'center',
                    display: this.showPopup? 'block': 'none',
                    zIndex: 99,
                  }
                }>
                <div style={{fontSize: 80, transform: "rotate(20deg)", position:"absolute", right:15, top:-20, color: '#FFF', textAlign:'right'}}>↑</div>
                <br/>
                <br/>
                <br/>
                1. click the top right corner icon<br /><br />
                2. click open in browser <br /><br /><img src="/open_in_browser.png" /><br />
                <br />
                <Button onClick={() => this.cleanPopup()}>OK</Button>
              </div>
              {this.checkedButFailed &&
                <div>
                  <h2>
                    You haven't upload the file yet, try again?
                  </h2>
                  <br />
                  <Button onClick={() => this.showPopupAgain()}>Try again</Button>
                  <Button onClick={() => window.history.back()}>Cancel upload</Button>
                </div>
              }
            </div>}
            {!this.state.extBrowser && !this.state.uploadFin && <div>
             <div>
              <Form>
                <FormCell>
                  <Label>PLEASE CREATE A NAME OF YOUR FILE:</Label>
                  <Input onChange={(e) => this.onChangeName(e)} type="text" />
                </FormCell>
              </Form>
             </div>
             {this.state.fileName !== '' &&
            <ImageUplaod onChange={(v) => this.onChangeForm({ target: { value: v } }, 'document')} apiAddress={process.env.API_URL || window.env.API_URL} />
             }
            {this.state.form.document && <div>
              <Toptips type="primary" show={true}>File uploaded.  Upload again or confirm by clicking the button here.</Toptips>
              <Button onClick={() => this.shootChange()}>Confirm Upload</Button>
            </div>}
          </div>}
          {this.state.uploadFin && <div><h1>Upload is saved.  Please go back to wechat to continue.</h1></div>}
        </div>
        }
      </div>
    );
  }
}
