import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import './App.css'

import {Form, FormCell} from 'react-weui';
import {Label} from 'react-weui';
import {CellHeader, CellsTitle, CellBody} from 'react-weui';

import 'weui';
import 'react-weui/build/packages/react-weui.css';
import locations from '../shared/location';
import moment from 'moment';

@inject('routingStore', 'sessionStore','jobStore', 'uploadStore')
@observer
export default class Job_get_public extends Component {

  @observable tab;
  @observable jobId;
  @observable file;
  @observable isesUser = false;

  constructor(props, context) {
    super(props, context);
  }

  async componentWillMount() {
    this.jobId = /[^\/]+$/.exec(this.props.routingStore.location.pathname)[0];
    await this.props.jobStore.showPublicJob(this.jobId);
    await this.props.jobStore.getSpecialties();
    await this.props.jobStore.getFunctions();
    if (this.props.jobStore.currentJob.upload) {
      this.file = await this.props.uploadStore.getUpload({ code: this.props.jobStore.currentJob.upload });
    }
    if (this.props.sessionStore.token) {
      this.isesUser = true;
    }
    document.title = this.props.jobStore.currentJob.name;
  }

  getSalaryRange() {
    try {
      var range = [
        { label: "< $   500,000 HKD" },
        { label: "~ $   800,000 HKD" },
        { label: "~ $ 1,000,000 HKD" },
        { label: "> $ 1,500,000 HKD" }
      ];
      return range[this.props.jobStore.currentJob.salaryRange].label;
    } catch (err) {

    }
  }

  jobSpecialties() {
    try {
      if (this.props.jobStore.specialties && this.props.jobStore.currentJob) {
        const id = toJS(this.props.jobStore.currentJob.specialties)
        const special = toJS(this.props.jobStore.specialties[id - 1]);
        return special.name
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {

    }
  }

  jobSubSpecialties() {
    try {
      if (this.props.jobStore.specialties && this.props.jobStore.currentJob) {
        const jsid = toJS(this.props.jobStore.currentJob.specialties);
        const id = toJS(this.props.jobStore.currentJob.subSpecialties);
        const special = toJS(this.props.jobStore.specialties[jsid - 1]);
        return special.subSpecialties[id - special.subSpecialties[0].id].name
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {

    }
  }

  jobFunctions() {
    try {
      if (this.props.jobStore.functions) {
        const id = toJS(this.props.jobStore.currentJob.function);
        const functions = toJS(this.props.jobStore.functions[id - 1]);
        return functions.name;
      } else {
        return [{
          value: 1,
          label: 'loading...'
        }]
      }
    } catch (err) {

    }
  }

  getTitle() {
    try {
      var title = toJS(this.props.jobStore.currentJob.title);
      return title[0].toUpperCase()+title.slice(1);
    } catch (err) {

    }
  }

  getDate() {
    try {
      const date = moment(this.props.jobStore.currentJob.created_at).utcOffset(8);
      return date.format('MMMM Do YYYY, h:mm a');
    } catch (err) {

    }
  }

  render() {
    return (
      <div style={{backgroundColor: "#eee", paddingBottom:50}}>
        <div style={{display: this.isesUser? 'block': 'none', textAlign: "center", backgroundColor: "#f1f1f1", color:"#000", padding:2, fontSize:9}}>You are safe to share this directly to your candidate via any apps</div>
        <div className="App-header">
          <h2>{ this.props.jobStore.currentJob.name }</h2>
          Published at { this.getDate() }
        </div>
        <Form>
          <FormCell>
            <CellHeader>
              <Label>Industry</Label>
            </CellHeader>
            <CellBody>
              { this.jobSpecialties() }
            </CellBody>
          </FormCell>
          <FormCell>
            <CellHeader>
              <Label>Specialties</Label>
            </CellHeader>
            <CellBody>
              { this.jobSubSpecialties() }
            </CellBody>
          </FormCell>
          <FormCell>
            <CellHeader>
              <Label>Function</Label>
            </CellHeader>
            <CellBody>
              { this.jobFunctions() }
            </CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Job Location</CellsTitle>
        <Form>
          <FormCell>
            <CellHeader>
              <Label>Province/City</Label>
            </CellHeader>
            <CellBody>
              { locations.getLocationByAbb(this.props.jobStore.currentJob.city) }
            </CellBody>
          </FormCell>
          <FormCell>
            <Label>Detailed Job Location</Label>
            { this.props.jobStore.currentJob.address || 'Not applicable.' }
          </FormCell>
        </Form>
        <CellsTitle>Details</CellsTitle>
        <Form>
          <CellsTitle>Highlight(s)</CellsTitle>
          <FormCell>
            <CellBody style={{whiteSpace: "pre-line"}}>
              { this.props.jobStore.currentJob.highlight || 'Not applicable.' }
            </CellBody>
          </FormCell>
        <CellsTitle>Job Description</CellsTitle>
          <FormCell>
            <CellBody style={{whiteSpace: "pre-line"}}>
              { this.props.jobStore.currentJob.jobDescription || 'Not applicable.' }
            </CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Qualifications</CellsTitle>
        <Form>
          <FormCell>
            <CellBody style={{whiteSpace: "pre-line"}}>
              { this.props.jobStore.currentJob.qualifications || 'Not applicable.' }
            </CellBody>
          </FormCell>
        </Form>
        <CellsTitle>Salary package in HKD</CellsTitle>
        <Form>
          <FormCell>
            <CellBody>
              { this.getSalaryRange() }
            </CellBody>
          </FormCell>
        </Form>
      </div>)
  }
}
