import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import './App.css';
import location from '../shared/location';

import {Form, FormCell} from 'react-weui';
import {Label, Button} from 'react-weui';
import {CellHeader, CellsTitle, CellBody} from 'react-weui';
import {Icon} from 'antd'

import 'weui';
import moment from 'moment';
import 'react-weui/build/packages/react-weui.css';
import {NonCacheTabNav} from './NonCacheTabNav';

@inject('sessionStore','routingStore', 'candidateStore', 'jobStore', 'uploadStore')
@observer
export default class Candidate_get extends Component {

  @observable currentJs = 1;
  @observable jobId;
  @observable candidateId;
  @observable file = {};
  @observable candidate = {};

  constructor(props, context) {
    super(props, context);
    this.jobId = /[^job/]+/.exec(this.props.routingStore.location.pathname)[0];
    this.candidateId = /[^\/]+$/.exec(this.props.routingStore.location.pathname)[0];
  }

  async componentWillMount() {
    var params = {};
    await this.props.jobStore.showJob(this.jobId);
    if (!!this.props.jobStore.currentJob.isMine) {
      params.jobOwner = true;
    }
    await this.props.candidateStore.showCandidate(this.candidateId, params);
    this.candidate = toJS(this.props.candidateStore.currentCandidate);
    if (this.props.candidateStore.currentCandidate.upload) {
      this.file = await this.props.uploadStore.getUpload({ code: this.props.candidateStore.currentCandidate.upload });
    }
  }

  async reject() {
    const json = await this.props.candidateStore.rejectCandidate(this.candidate.id);
    if (json) {
      await this.props.candidateStore.showCandidate(this.candidateId, {jobOwner: true});
      this.candidate = toJS(this.props.candidateStore.currentCandidate);
    }
  }

  async accept() {
    const json = await this.props.candidateStore.acceptCandidate(this.candidate.id);
    if (json) {
      await this.props.candidateStore.showCandidate(this.candidateId, {jobOwner: true});
      this.candidate = toJS(this.props.candidateStore.currentCandidate);
    }
  }

 render() {
   return (
     <div style={{backgroundColor: "#eee", paddingBottom:50}}>
     <div className="App-header">
       <h2>View Candidate</h2>
       Candidate for {this.props.jobStore.currentJob.name} {this.props.jobStore.currentJob.title}
     </div>
       { !this.candidate.isMine && <div>
       <CellsTitle>Referrer Profile</CellsTitle>
       <Form>
         <FormCell>
           <Label>Name</Label>
           { this.candidate.providerFirstName }&nbsp;{ this.candidate.providerLastName }
         </FormCell>
         <FormCell>
           <Label>WeChat</Label>
           { this.candidate.providerWeChat }
         </FormCell>
       </Form></div>
       }
       <CellsTitle>Candidate Status</CellsTitle>
       { !!this.candidate.isMine &&
       !this.candidate.agreed_jobOwner &&
       !this.candidate.declined_jobOwner &&
       <Form>
         <FormCell>
       <span>Poster didn't respond yet.</span>
         </FormCell>
       </Form>
       }
       { !!this.candidate.isMine &&
       !!this.candidate.agreed_jobOwner &&
       !this.candidate.agreed_candidate &&
       !this.candidate.delined_candidate &&
       <Form>
         <FormCell>
           <span>Poster has accepted your candidate, now awaiting candidate to verify.</span>
         </FormCell>
       </Form>
       }
       <Form>
       {
       !!this.candidate.agreed_candidate &&
       !!this.candidate.agreed_jobOwner &&
        !this.candidate.status_presenting &&
        !this.candidate.status_interview_1 &&
        !this.candidate.status_interview_2 &&
        !this.candidate.status_interview_3 &&
        !this.candidate.status_hired &&
        !this.candidate.status_rejected &&
       <div>
         <FormCell>
           <span>Candidate has accepted this recommendation, you can contact each other to proceed further.</span>
           <br/>
           <br/>
           <Button onClick={() => this.props.routingStore.push(`/job/${this.jobId}/candidate/${this.candidateId}/status_update`)}>Update Candidate Status</Button>
         </FormCell>
       </div>
			 }
       {
       (!!this.candidate.status_presenting ||
       !!this.candidate.status_interview_1 ||
       !!this.candidate.status_interview_2 ||
       !!this.candidate.status_interview_3 ||
       !!this.candidate.status_hired ||
       !!this.candidate.status_rejected ) &&
       <div>
         <FormCell>
           { !!this.candidate.status_hired && 
           <div>
            <Label>{moment.utc(this.candidate.status_hired).utcOffset(8).format('D/M/YY, h:mm a')}</Label>
            <b>Hired</b>
            <p style={{fontSize: '0.8rem', marginBottom: '1rem'}}>Note: {this.candidate.status_hired_info}</p>
           </div>
           }
           { !!this.candidate.status_rejected && 
           <div>
            <Label>{moment.utc(this.candidate.status_rejected).utcOffset(8).format('D/M/YY, h:mm a')}</Label>
            <b>Rejected</b>
            <p style={{fontSize: '0.8rem', marginBottom: '1rem'}}>Note: {this.candidate.status_rejected_info}</p>
           </div>
           }
           { !!this.candidate.status_interview_3 && 
           <div>
            <Label>{moment.utc(this.candidate.status_interview_3).utcOffset(8).format('D/M/YY, h:mm a')}</Label>
            <b>3rd Interview</b>
            <p style={{fontSize: '0.8rem', marginBottom: '1rem'}}>Note: {this.candidate.status_interview_3_info}</p>
           </div>
           }
           { !!this.candidate.status_interview_2 && 
           <div>
            <Label>{moment.utc(this.candidate.status_interview_2).utcOffset(8).format('D/M/YY, h:mm a')}</Label>
            <b>2nd Interview</b>
            <p style={{fontSize: '0.8rem', marginBottom: '1rem'}}>Note: {this.candidate.status_interview_2_info}</p>
           </div>
           }
           { !!this.candidate.status_interview_1&& 
           <div>
            <Label>{moment.utc(this.candidate.status_intreview_1).utcOffset(8).format('D/M/YY, h:mm a')}</Label>
            <b>1st Interview</b>
            <p style={{fontSize: '0.8rem', marginBottom: '1rem'}}>Note: {this.candidate.status_interview_1_info}</p>
           </div>
           }
           { !!this.candidate.status_presenting && 
           <div>
            <Label>{moment.utc(this.candidate.status_presenting).utcOffset(8).format('D/M/YY, h:mm a')}</Label>
            <b>Presenting</b>
            <p style={{fontSize: '0.8rem', marginBottom: '1rem'}}>Note: {this.candidate.status_presenting_info}</p>
           </div>
           }
           <br/>
           <Button onClick={() => this.props.routingStore.push(`/job/${this.jobId}/candidate/${this.candidateId}/status_update`)}>Update Candidate Status</Button>
         </FormCell>
       </div>
			 }
       { !!this.candidate.isMine &&
       !!this.candidate.agreed_candidate &&
       !!this.candidate.agreed_jobOwner &&
         <div>
         <FormCell>
           <Label>Poster Name</Label>
           <span>{this.candidate.ownerFirstName} {this.candidate.ownerLastName}</span>
         </FormCell>
         <FormCell>
           <Label>Poster Contact</Label>
           <span>Mobile: +{this.candidate.ownerCountryCode} {this.candidate.ownerMobile}</span><br />
           <span>WeChat: {this.candidate.ownerWeChat}</span>
         </FormCell>
         </div>
       }
       </Form>
       {
       !!this.candidate.declined_jobOwner &&
       !!this.candidate.agreed_jobOwner &&
       <Form>
         <FormCell>
           <Label>Candidate Status</Label>
           <span>Candidate has declined this recommendation.</span>
         </FormCell>
       </Form>
       }
       { !!this.candidate.isMine &&
       !!this.candidate.declined_jobOwner &&
       <Form>
         <FormCell>
           <Label>Status</Label>
           <span>Poster has declined this candidate.</span>
         </FormCell>
       </Form>
       }
       <CellsTitle>Candidate Profile</CellsTitle>
       <Form>
         <FormCell>
             <Label>Current Title</Label>
           { this.candidate.title }
         </FormCell>
         <FormCell>
           <CellBody>
             <Label style={{marginBottom: 10,whiteSpace: "pre-line"}}>Summary</Label>
             { this.candidate.profile }
           </CellBody>
         </FormCell>
         <FormCell>
           <Label>Current Salary</Label>
           { this.candidate.last_salary }
         </FormCell>
         <FormCell>
           <Label>Expected Salary</Label>
           { this.candidate.expected_salary }
         </FormCell>
       </Form>
       { !!this.candidate.terms &&
         <div>
           {!!this.candidate.isMine &&
           <CellsTitle>Additional term for poster</CellsTitle>
           }
           {!this.candidate.isMine &&
           <CellsTitle>Referrer would like you to accept this additional term</CellsTitle>
           }
       <Form>
         <FormCell>
           <CellBody>
             { this.candidate.terms }
           </CellBody>
         </FormCell>
       </Form>
         </div>
         }
       <br/>
       { (!!this.candidate.isMine || !!this.candidate.agreed_jobOwner) &&
         <div>
           <hr/>
       <CellsTitle>Candidate CV<br/>CONTENTS WILL BE SHOWN TO POSTER UPON ACCEPTANCE OF TERMS AND CONDITIONS</CellsTitle>
      {this.file && this.file.fileName &&
        <Form>
          <FormCell>
            <CellHeader><Label>CV file</Label></CellHeader>
            <CellBody>
              <div style={{textAlign: 'center', margin: 10}}><Icon type="file" theme="twoTone" /><br />{this.file.fileName}</div>
              <Button onClick={() => window.location = this.file.document}>click to open</Button>
            </CellBody>
          </FormCell>
        </Form>
      }
       <Form>
      { !(this.file && this.file.fileName) &&
           <FormCell>
             <Label>First Name</Label>
             {this.candidate.first_name || 'Not applicable.' }
           </FormCell>
      }
      { !(this.file && this.file.fileName) &&
           <FormCell>
             <Label>Last Name</Label>
             {this.candidate.last_name || 'Not applicable.' }
           </FormCell>
      }
      { !(this.file && this.file.fileName) &&
           <FormCell>
             <Label>Mobile No.</Label>
             {this.candidate.tel || 'Not applicable.' }
           </FormCell>
      }
      { !(this.file && this.file.fileName) &&
           <FormCell>
             <Label>WeChat</Label>
             {this.candidate.wechat || 'Not applicable.' }
           </FormCell>
      }
      { !(this.file && this.file.fileName) &&
           <FormCell>
             <Label>Province/City</Label>
             {location.getLocationByAbb(this.props.candidateStore.currentCandidate.city)}
           </FormCell>
      }
      { !(this.file && this.file.fileName) &&
           <FormCell>
             <Label>Address</Label>
             {this.candidate.address || 'Not applicable.' }
           </FormCell>
      }
      { !(this.file && this.file.fileName) &&
          <div>
            <CellsTitle>Working Experience</CellsTitle>
            <FormCell>
              <CellBody style={{ whiteSpace: "pre-line" }}>
                {this.candidate.cv_workingExp || 'Not applicable.' }
              </CellBody>
            </FormCell>
          </div>
      }
      { !(this.file && this.file.fileName) &&
          <div>
           <CellsTitle>Qualifications</CellsTitle>
           <FormCell>
             <CellBody style={{ whiteSpace: "pre-line" }}>
               {this.candidate.cv_qualification || 'Not applicable.' }
             </CellBody>
           </FormCell>
          </div>
      }
       </Form>
         </div>
       }
       { !this.candidate.isMine && !!this.candidate.declined_jobOwner &&
       <div style={{textAlign: "center"}}>You had declined this candidate.</div>
       }
       { !this.candidate.isMine &&
         !this.candidate.agreed_jobOwner &&
         !this.candidate.declined_jobOwner &&
         !!this.candidate.terms &&
         <div>
             <Button type="warn" onClick={() => this.reject()}>I reject the additional terms</Button>
             <Button onClick={() => this.accept()}>I accept the additional terms</Button>
         </div>
       }
       { !this.candidate.isMine &&
         !this.candidate.agreed_jobOwner &&
         !this.candidate.declined_jobOwner &&
         !this.candidate.terms &&
       <div>
         <Button onClick={() => this.accept()}>View CV</Button>
       </div>
       }
       { !this.candidate.agreed_candidate &&
         this.candidate.agreed_jobOwner &&
         <div style={{textAlign: "center"}}>A verification message is sent to candidate mobile, his/her detailed information will be shown when candidate accept the referrer's recommendation.</div>
       }
       { this.candidate.declined_candidate &&
         this.candidate.agreed_jobOwner &&
       <div style={{textAlign: "center"}}>Candidate rejects the referrer's recommendation.</div>
       }
       <NonCacheTabNav />
   </div>)
 }
}
