import {observable, action} from 'mobx'
import ClientStorage from '../shared/ClientStorage'
import api from '../shared/api'

export class SessionStore {

  @observable token = null;
  @observable wxOpenId = null;
  @observable User = {};
  @observable NewJob = null;
  @observable NewCandidate = {};

  constructor() {
    this.storage = new ClientStorage();
    if (this.storage.get('token')) {
      this.token = this.storage.get('token');
    }
    if (this.storage.get('wxOpenId')) {
      this.wxOpenId = this.storage.get('wxOpenId');
    }
    if (this.storage.get('User')) {
      this.User = this.storage.get('User');
    }
    if (this.storage.get('NewJob')) {
      this.NewJob = this.storage.get('NewJob');
    }
    if (this.storage.get('NewCandidate')) {
      this.NewCandidate = this.storage.get('NewCandidate');
    }
  }

  @action('saveNewJob') saveNewJob(job) {
    this.NewJob = job;
    this.storage.set('NewJob', job);
  }

  @action('saveNewCandidate') saveNewCandidate(candidate, jobId) {
    this.NewCandidate = {...this.NewCandidate, [jobId]: candidate};
    this.storage.set('NewCandidate', {...this.NewCandidate, [jobId]: candidate});
  }

  @action('getNewCandidate') getNewCandidate(jobId) {
    return this.NewCandidate[jobId];
  }

  @action('getNewJob') getNewJob() {
    return this.NewJob;
  }

  @action('removeNewJob') removeNewJob(job) {
    this.NewJob = null;
    this.storage.del('NewJob');
  }

  @action('destroy') async destroy() {
    await api.delete('users', this.User);
    this.User = {};
    this.token = null;
    this.wxOpenId = null;
    this.NewJob = null;
    this.storage.del('User');
    this.storage.del('token');
    this.storage.del('wxOpenId');
    this.storage.del('NewJob');
    this.storage.del('NewCandidate');
  }

  @action('getWxOpenId') async getWxOpenId(wxAuthcode) {
    const json = await api.create('wxauth', {wxAuthcode});
    this.wxOpenId = json.openid;
    this.storage.set('wxOpenId', json.openid);
  }

  @action('createUser') async createUser(User) {
    if (!this.wxOpenId) return;
    User.wxOpenId = this.wxOpenId;
    User.password = 'wechatlogin';
    const json = await api.create('users', User);
    if (json) {
      this.storage.set('User', json);
      return true;
    } else {
      return false;
    }
  }

  @action('patchUser') async patchUser(User) {
    if (!User) return;
    const json = await api.patch('users', User);
    if (json) {
      this.storage.set('User', json[0]);
      this.User = json[0];
      return true;
    } else {
      return false;
    }
  }

  @action('submitTierRequest') async submitPremissionRequest({ premissionName }) {
    if (!this.User) return;
    const json = await api.create('membership-tier-request', { requestingPremission: premissionName });
    if (json) {
      return true;
    } else {
      return false;
    }
  }

  @action('getUser') async getUser() {
    const User = await api.fetch(`users`, {wxOpenId: this.wxOpenId});
    if (User) {
      return User;
    } else {
      return null;
    }
  }

  @action('refreshMembership') async refreshMembershipStatus() {
    let User = (await this.getUser()).data[0];
    this.User = User;
    this.storage.set('User', this.User);
  }

  @action('login') async login(wxAuthcode) {
    await this.getWxOpenId(wxAuthcode);
    var credential = {
      strategy: 'local',
      wxOpenId: this.wxOpenId,
      password: 'wechatlogin'
    }
    const json = await api.login(credential);
    this.token = json.accessToken;
    this.storage.set('token', this.token);
    if (json && json.code === 401) {
      return null;
    } else {
      let User = (await this.getUser()).data[0];
      this.User = User;
      this.storage.set('User', this.User);
      return User;
    }
  }

  @action('logout') async logout() {
    this.token = null;
    this.storage.del('User');
    this.storage.del('wxOpenId');
    this.storage.del('NewJob');
  }
}

export default new SessionStore();
