import React from 'react';
import {Input, Button} from 'react-weui';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

@inject('routingStore', 'invitationStore')
@observer
export default class NotFound extends React.Component{

  @observable code;

  constructor(props, context) {
    super(props, context);
    this.state = {
      confirmBtn: true
    }
  }

  async handleSubmit() {
    try {
      this.setState({ confirmBtn: false });
      const json = await this.props.invitationStore.verifyInvitationCode(this.code);
      if (json) {
        window.location = `/registration?code=${this.code}`
      }
    } catch (e) {
      alert('Invitation code not found, please contact us!');
    }
  }

  render() {
    return <div style={{paddingTop: '6em', textAlign: 'center'}}>
      <style>{`
      @-webkit-keyframes show {
        0% {opacity: 0}
      }
      @keyframes show {
        0% {opacity: 0}
      }
      @-webkit-keyframes mymove {
        0% {bottom: -200px; opacity: 0}
      }
      @keyframes mymove {
        0% {bottom: -200px; opacity: 0}
      }
      body{
        background: #132546;
      }
      .tip {
        -webkit-animation: mymove 2s;
        animation: mymove 2s;
      }
      .App-logo {
        padding-top: 0;
        -webkit-animation: show 5s;
        animation: show 5s;
      }
    `}</style>
      <span className={'App-logo'}>UPRO</span>
      <div className={'tip'} style={{position:'absolute', bottom:0, backgroundColor: '#FFF', width: '100%', padding: '10%', textAlign: 'left'}}>
        <h2>Power up your network.</h2>
        <span style={{color: '#6f6f6f'}}>Utilise your knowledge and expertise.</span><br/><br/>
        <Input className={'welcome-invitation'} defaultValue='Enter Invitation Code' onClick={(v) => {
          if (v.target.value === 'Enter Invitation Code') {
            v.target.value = ''
          }
        }} onChange={(v) => this.code = v.target.value} />
        <Button onClick={() => this.handleSubmit()} {...this.state.confirmBtn? '': 'disabled'}>Confirm Invitation Code</Button>
      </div>
    </div>
  }
}