import '@babel/polyfill';
if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

var process = [];
process.env = Object.assign({}, process.env, window.env);

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { CookiesProvider } from 'react-cookie';
import Routes from './Routes';
import stores from './stores';
import './index.css';

ReactDOM.render(
  <CookiesProvider>
  <Provider {...stores}>
    <Routes/>
  </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./components/App.jsx', () => {
    const NextRoutes = require('./Routes').default // eslint-disable-line
    ReactDOM.render(
      <CookiesProvider>
      <Provider {...stores}>
        <NextRoutes/>
      </Provider>
      </CookiesProvider>,
      document.getElementById('root'),
    )
  })
}